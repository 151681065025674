<template>
<!-- NOTAS UPLOAD CONTENT ========================================-->
    <div class="content__form--nota" key="notas">
        <h2><strong>{{$t('FORM_NOTA_TITLE')}}</strong></h2>
        <p>{{$t('FORM_NOTA_SUB_TITLE')}}</p>
        <form class="form--notas">
            <legend>{{$t('FORM_NOTA_LABEL_NOTA')}}</legend>

            <div class="form__content grid-form-60">
                <label for="nome" class="requerid">{{$t('FORM_NOTA_NAME')}}</label>
                <input v-model="invoice.customerFullName" name="nome" type="text" placeholder="">
                <span v-show="send && !validation.customerFullName" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
            </div>

            <div class="form__content file__upload grid-form-40">
                <label for="upload" class="requerid ">{{$t('FORM_NOTA_FILE')}}</label>
                <input @change="upload($event)" name="upload" type="file" placeholder="">
                <span v-show="send && !validation.invoiceFile" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
            </div>

            <div class="form__content grid-form-70">
                <label for="cnpj" class="requerid ">{{$t('FORM_NOTA_CNPJ')}}</label>
                <input v-model="invoice.cnpj" name="cnpj" type="text" placeholder="">
                <span v-show="send && !validation.cnpj" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
            </div>

            <div class="form__content grid-form-30">
                <label for="data" class="requerid ">{{$t('FORM_NOTA_DATA')}}</label>
                <input v-model="invoice.purchaseDate" name="data" type="date" placeholder="">
                <span v-show="send && !validation.purchaseDate" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
            </div>

            <div class="form__content grid-form-60">
                <label for="invoice" class="requerid ">{{$t('FORM_NOTA_INVOICE')}}</label>
                <input v-model="invoice.number" name="invoice" type="text" placeholder="">
                <span v-show="send && !validation.number" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
            </div>

            <div class="form__content grid-form-40">
                <label for="valor" class="requerid ">{{$t('FORM_NOTA_VALUE')}}</label>
                <input v-model="invoice.amount" name="valor" type="number" min="0" placeholder="9999.99">
                <span v-show="send && !validation.amount" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
            </div>

            <div class="content--legend">
                <legend class="sub--legend grid-form-100">{{$t('FORM_NOTA_LABEL_PROD')}}</legend>
                <button @click="addInvoiceProduct()" type="button" class="btn--prod"><svg viewBox="0 0 24 24" fill="none" ><path d="M4 12H20M12 4V20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>Produto</button>
            </div>

            <!-- BOX PROD ========================================-->
            <div class="module--prod grid-form-100" v-for="(invoiceProduct, idx) in invoice.invoiceProducts">
                <button type="button" @click="removeInvoiceProduct(idx)" class="btn--del" data-tooltip="Excluir Produto" data-flow="left">
                    <svg viewBox="0 0 1024 1024"><path d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/></svg>
                </button>

                <div class="form__content grid-form-50">
                    <label for="prods" class="requerid">{{$t('FORM_NOTA_MODEL')}}</label>
                    <select @change="getProducts(invoiceProduct.productModelId); invoiceProduct.productId = ''" v-model="invoiceProduct.productModelId" name="prods" id="prods" class="select">
                        <option value="" disabled selected>{{$t('FORM_NOTA_MODEL_DESC')}}</option>
                        <option v-for="productModel in productModels" :value="productModel.id">{{productModel.name}}</option>
                    </select>
                    <span v-show="send && !validation.invoiceProducts[idx].productModelId" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
                </div>

                <div class="form__content grid-form-50">
                    <label for="prods" class="requerid">{{$t('FORM_NOTA_PROD')}}</label>
                    <select v-model="invoiceProduct.productId" name="prods" id="prods" class="select">
                        <option value="" disabled selected>{{$t('FORM_NOTA_PRODUCT_DESC')}}</option>
                        <option v-for="product in products[invoiceProduct.productModelId]" :value="product.id">{{product.name}}</option>
                    </select>
                    <span v-show="send && !validation.invoiceProducts[idx].productId" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
                </div>

                <div class="form__content grid-form-70">
                    <label for="valor" class="requerid ">{{$t('FORM_NOTA_PROD_VALUE')}}</label>
                    <input v-model="invoiceProduct.amount" name="valor" type="number" min="0" placeholder="9999.99">
                    <span v-show="send && !validation.invoiceProducts[idx].amount" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
                </div>

                <div class="form__content grid-form-30">
                    <label for="valor" class="requerid ">{{$t('FORM_NOTA_PROD_QUANT')}}</label>
                    <input v-model="invoiceProduct.quantity" name="valor" type="number" min="0" placeholder="10">
                    <span v-show="send && !validation.invoiceProducts[idx].quantity" class="error__form" >{{$t('FORM_NOTA_ALERT')}}</span>
                </div>

            </div>
            <div class="module--prod--text grid-form-100">
                    
                    <div id="1" class="boxes">
                        <!-- <p>Esse produto está participando do programa de pontos: <strong>NOME DO PROGRAMA</strong>. <br> Por favor, aceite os termos para participar e pontuar.</p> -->
                        <div class="form__content toggles grid-form-100">
                            <input :disabled="optInCampaign === true" v-model="invoice.optInCampaign" id="programa-pontos" type="checkbox">
                            <label for="programa-pontos" class="checkbox"> 
                                <a href="regulamento-lg-magenta-programa-pontos.pdf" target="_blank" >{{$t('FORM_NOTA_OPTIN')}}</a></label>
                            <span v-show="send && !validation.optInCampaign" class="error__form" >{{$t('REQUIRED_1')}}</span>
                        </div>
                    </div>

                    <!-- <div v-show="invoiceProduct.campaign.available === false" id="2" class="boxes alert--points">
                        <p><strong>Produto inválido!</strong><br>Esse produto não está participando de nenhum programa de pontos. <br> Por favor, exclua-o para continuar.</p>			
                    </div> -->

                </div>
            <!-- BOX PROD ========================================-->

            <div class="clear"></div>
        
            <div class="btn--content--finish grid-form-100">
                <button type="button" @click="sendInvoice()" class="btn--send">Enviar nota</button>
            </div>
            <span v-show="feedback" class="send--invoice grid-form-100" >{{feedback}}</span>

        </form>
    </div>
    <!-- NOTAS UPLOAD CONTENT ========================================-->
</template>


<script>
import App from './envioNotas.js'

export default App;
</script>