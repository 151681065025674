export default {
    props:{
        optInCampaign: Object
    },
    data(){
        return{
            invoice: {
                customerFullName: '',
                invoiceFile: {
                    extension: '',
                    file: ''
                },
                cnpj: '',
                purchaseDate: '',
                number: '',
                amount: '',
                invoiceProducts: [new this.createInvoiceProduct()],
                optInCampaign: false
            },
            products: {},
            productModels: [],
            send: false,
            feedback: ''
        }
    },
    created() {
        this.getProductModels()
        this.invoice.optInCampaign = this.optInCampaign
	},
    computed:{
        validation :function(){
			return {
				customerFullName: !!this.invoice.customerFullName && this.invoice.customerFullName.length > 0,
				invoiceFile:!!this.invoice.invoiceFile && this.invoice.invoiceFile.file?.length > 0 && this.invoice.invoiceFile.extension?.length > 0,
                cnpj: !!this.invoice.cnpj && this.invoice.cnpj.length > 0,
				purchaseDate:!!this.invoice.purchaseDate && this.invoice.purchaseDate.length > 0,
                number: !!this.invoice.number && this.invoice.number.length > 0,
				amount:!!this.invoice.amount && this.invoice.amount > 0,
                invoiceProducts: this.invoiceProductsValidation(),
                optInCampaign: this.invoice.optInCampaign && (typeof(this.invoice.optInCampaign) === typeof(true))
            }		
		},
		isValid : function(){
			var ret = true;
			Object.entries(this.validation).forEach(([key, value]) => {
                if(Array.isArray(value))
                    value.forEach(val =>{
                        Object.entries(val).forEach(([key, value]) => {
                            if(!value)
                                ret = false;
                        });
                    })    
                if(!value)
					ret = false;
            });
			return ret;
		},
    },
    methods: {
        getProductModels(){
            this.$store.dispatch("product/getModels")
            .then(
                response => {
                    console.log(response.data?.rows)
                    this.productModels = response.data?.rows    
                }, 
                error =>{
                    this.feedback = this.$i18n.t('FORM_NOTA_PROD_ERRO')
                    // console.log(error.response.rows[0].message)
                }
            ).catch((erro) =>{
                this.feedback = this.$i18n.t('FORM_NOTA_PROD_ERRO')
                console.log(erro)
            });
        },
        getProducts(modelId){
            this.$store.dispatch("product/getProducts", modelId)
            .then(
                response => {
                    console.log(response.data?.rows)
                    this.products[modelId] = response.data?.rows    
                }, 
                error =>{
                    this.feedback = this.$i18n.t('FORM_NOTA_PROD_ERRO')
                    // console.log(error.response.rows[0].message)
                }
            ).catch((erro) =>{
                this.feedback = this.$i18n.t('FORM_NOTA_PROD_ERRO')
                // console.log(erro)
            });
        },
        createInvoiceProduct(){
            this.productId= '',
            this.productModelId= '',
            this.amount = '',
            this.quantity = ''
        },
        addInvoiceProduct(){
            this.invoice.invoiceProducts.push(new this.createInvoiceProduct())
        },
        removeInvoiceProduct(index){    
            this.invoice.invoiceProducts.splice(index, 1)

            if(this.invoice.invoiceProducts.length <= 0)
                this.addInvoiceProduct();
        },
        upload(e){
            var self = this;
            var reader   = new FileReader();
            var fl = e.target.files[0];
            reader.readAsDataURL(fl);
            reader.onload = function (evt) {
                var rs = reader.result;
                rs = rs.split(',');
                let ext = ''

                if(fl?.name && fl.name.lastIndexOf('.') > 0 && fl.name.length > fl.name.lastIndexOf('.'))
                    ext = fl.name.substring(fl.name.lastIndexOf('.')+1)
                self.invoice.invoiceFile.file = rs[1]
                self.invoice.invoiceFile.extension = ext
                // e.target.value = '';
            }
        },
        invoiceProductsValidation(){
            let invoiceProducts = []

            for(let i = 0; i < this.invoice.invoiceProducts.length; i++)
                invoiceProducts.push({
                    productId: !!this.invoice.invoiceProducts[i].productId,
                    productModelId: !!this.invoice.invoiceProducts[i].productModelId,
                    amount: !!this.invoice.invoiceProducts[i].amount && this.invoice.invoiceProducts[i].amount > 0,
                    quantity: !!this.invoice.invoiceProducts[i].quantity && this.invoice.invoiceProducts[i].quantity > 0
                })
            return invoiceProducts
        },
        sendInvoice(){
            this.send = true;

            if(this.isValid){
                this.$store.dispatch("invoice/postInvoice", this.invoice)
                .then(
                    response => {
                        if(response.status === 201){
                            this.feedback = this.$i18n.t('FORM_NOTA_SUCESSO')
                            this.send = false
                            this.resetInvoice();
                            this.invoice.optInCampaign = true;
                        }else{
                            this.feedback = this.$i18n.t('FORM_NOTA_ERRO')
                            console.log(response)
                        }
                    }, 
                    error =>{
                        if(error.response?.data?.message)
                            this.feedback = error.response.data.message
                        else    
                            this.feedback = this.$i18n.t('FORM_NOTA_ERRO')
                        console.log(error.response.data.message)
                    }
                ).catch((erro) =>{
                    if(erro.response?.data?.message)
                            this.feedback = erro.response.data.message
                        else    
                            this.feedback = this.$i18n.t('FORM_NOTA_ERRO')
                    console.log(erro)
                });
            }else
                setTimeout(function(){
                    document.getElementsByClassName("error__form")[0].scrollIntoView(false);
                },50);
        },
        resetInvoice(){
            this.invoice = {
                customerFullName: '',
                invoiceFile: {
                    extension: '',
                    file: ''
                },
                cnpj: '',
                purchaseDate: '',
                number: '',
                amount: '',
                invoiceProducts: [new this.createInvoiceProduct()],
                optInCampaign: this.optInCampaign
            }
        }
    }
}