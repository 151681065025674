<template>


	<!--[Start] CONTEUDO =========================================== -->
	<main class="perfil grid">

	
		<aside class="sidebar col-30">
			<h2>{{$t('PROFILE')}}</h2>

			<div class="sidebar__content">

				<!-- VIP CODE -->
				<!-- <div v-if="formCopy.vip || formCopy.vipRegister" class="vip--code">

					<p>{{$t('MEU_VIP')}}</p>

					<div class="vip--code__content">
						<span>
							{{formCopy.vip?
							formCopy.vip?.code:
							$t('WAITING_POINTS')}}
						</span>
					</div>
				</div> -->

				<!-- VIP CODE -->

				<!-- <a v-if="!formCopy.vip && !formCopy.vipRegister" href="#vip" @click="$event.preventDefault; isVip=true; isPass = false; isEditing = true;" class="rewards--btn">
					<span>{{$t('JOIN_POINTS')}}</span>		
					<svg viewBox="0 0 299.34 299.34"><path d="M282.868 56.803H204.27l6.877-6.158a9.17 9.17 0 001.35-1.509c10.05-14.091 6.762-33.731-7.331-43.781A28.818 28.818 0 00181.941.743a28.828 28.828 0 00-18.837 14.345L149.67 39.887l-13.434-24.799A28.825 28.825 0 00117.399.742a28.819 28.819 0 00-23.225 4.612c-14.091 10.05-17.38 29.69-7.331 43.781a9.225 9.225 0 001.35 1.509l6.877 6.158H16.474a9.18 9.18 0 00-9.18 9.18v58.204a9.18 9.18 0 009.18 9.18h2.432V290.16a9.18 9.18 0 009.18 9.18h243.17a9.18 9.18 0 009.18-9.18V133.366h2.432a9.18 9.18 0 009.18-9.18V65.983a9.18 9.18 0 00-9.18-9.18zm-103.62-32.97c1.446-2.671 3.873-4.519 6.833-5.204a10.611 10.611 0 012.401-.277c2.142 0 4.221.664 6.024 1.95a12.941 12.941 0 015.285 8.457 12.939 12.939 0 01-1.751 8.977l-21.295 19.067h-15.357l17.86-32.97zM99.549 28.76a12.94 12.94 0 015.286-8.457c2.473-1.764 5.463-2.359 8.425-1.673 2.959.684 5.386 2.533 6.833 5.204l17.86 32.969h-15.357L101.3 37.736a12.937 12.937 0 01-1.751-8.976zm15.959 252.221H37.265V133.367h78.243v147.614zm49.964 0h-31.604V133.367h31.604v147.614zm96.604 0h-78.243V133.367h78.243v147.614zm11.612-165.974H25.653V75.163h248.034v39.844z"/></svg>
				</a>
				<a v-if="(formCopy.vip || formCopy.vipRegister) && !formCopy.optInVipDouble" href="#vip-double" @click="$event.preventDefault; isVipDouble=true; isVip=true; isPass = false; isEditing = true;" class="double--points">
					
					
					<div class="double--points__content">
							<span>{{$t('DOUBLE_POINTS_1')}}</span>	
							<span>{{$t('DOUBLE_POINTS_2')}}</span>			
					</div>
					<img src="@/assets/img/2x-points.svg" alt="{{$t('DOUBLE_POINTS_2')}}">	
				</a> -->

				<!-- <a href="http://" class="btn-poits-resgate" target="_blank" rel="noopener noreferrer"><span>{{$t('MOLA_POINTS')}}</span>
					<svg  viewBox="0 0 24 24" ><path d="M20 11a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h6a1 1 0 0 0 0-2H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1z"/><path d="M16 5h1.58l-6.29 6.28a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L19 6.42V8a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2z"/></svg>
				</a> -->
			
				<h3>{{formCopy.user.name}}</h3>

				<p>{{$t('PROFILE')}}</p>
				<span>{{formCopy.cpf}}</span>

				<p>{{$t('BORN')}}</p>
				<span>{{formatData(formCopy.birthDate)}}</span>

				<p>{{$t('EMAIL')}}</p>
				<span>{{formCopy.user.email}}</span>
				
				<p>{{$t('PHONE')}}</p>
				<span>{{formCopy.cellPhone}}</span>

				<p>{{$t('PROFESSION')}}</p>
				<span>{{formCopy.profession}}</span>

				<p>{{$t('CAU_CFT')}}</p>
				<span>{{formCopy.professionalDoc}}</span>

				<p>{{$t('WORK_TIME')}}</p>
				<span>{{formCopy.workTimeExperience}}</span>

				<p>{{$t('INSTA')}}</p>
				<span>{{formCopy.instagram}}</span>
			</div>

			<button class="btn--stroke" type="button" @click="this.$store.dispatch('auth/logout')">{{$t('LOGOUT')}}</button>
		</aside>

		<div class="content__form col-70">

			<div class="perfil--subnav">
				<button v-for="item in tabsList" :key="item.id" :class="isActiveTab(item.id) ? 'active' : ''" @click="setActiveTab(item.id)" class="subnav--btn">{{item.label}}</button>
			</div>

			<Pontos v-if="activeTab === 'points'">
				<div class="perfil--subnav">
					<button v-for="item in tabsList" :key="item.id" :class="isActiveTab(item.id) ? 'active' : ''" @click="setActiveTab(item.id)" class="subnav--btn">{{item.label}}</button>
				</div>
			</Pontos>

			<EnvioNotas :optInCampaign="formCopy.optInCampaign"  v-if="activeTab === 'notas'" />

			<!-- FORM CONTENT ========================================-->
			<div class="content__form--dados" v-if="activeTab === 'dados'" key="dados">
				<div class="btn--content">
					<button class="btn--edit" v-show="isEditing == isPass" @click="isEditing = true; sending=false; feedback.message =''; isPass=false">{{$t('EDIT_PROFILE')}}</button>
					<button class="btn--edit senha" v-show="isEditing == isPass" @click="isEditing = false; isPass = true; sending=false; feedback.message =''">{{$t('ALTER_PASS')}}</button>
					<button class="btn--edit exit" @click="isEditing = false; isPass = false; isVip = false; isVipDouble=false" v-show="isEditing || isPass">X {{$t('EXIT')}}</button>
				</div>
			<form v-if="!isPass" class="form--dados">
				<legend>{{$t('PERSONAL_INFO_1')}}</legend>
				<div class="form__content grid-form-70">
                	<label for="nome" class="requerid">{{$t('FULL_NAME')}}</label>
               		<input :disabled="!isEditing" name="nome" type="text" placeholder="" v-model="form.name">
               		<span v-show="sending && !arrayValidation.name" class="error__form" >{{$t('FILL_NAME')}}</span>
            	</div>
				<div class="form__content grid-form-30">
                	<label for="nascimento" class="requerid" >{{$t('BORN')}}</label>
               		<input  placeholder="DD/MM/AAAA" v-maska="'##/##/####'" maxlength="10" :disabled="!isEditing" name="nascimento" type="text" v-model="form.birthDate">
               		<span v-show="sending && !arrayValidation.birthDate" class="error__form" >{{$t('FILL_DATA')}}</span>
            	</div>

				<div class="form__content grid-form-100">
                	<label for="cpf" class="requerid">{{$t('CPF')}}</label>
               		<input disabled name="cpf" type="text" placeholder="" v-model="form.CPF">
               		<!-- <span v-show="sending && !arrayValidation.CPF" class="error__form" >Preencha seu CPF, por favor.</span> -->
					<span class="input__disclaimer">{{$t('CPF_CHANGE')}}</span>
            	</div>
				
				<div class="clear"></div>
				<legend>{{$t('CONTACT')}}</legend>
				<div class="form__content grid-form-100">
                	<label for="mail" class="requerid">{{$t('EMAIL')}}</label>
               		<input name="mail" type="mail" :disabled="!isEditing" placeholder="" v-model="form.email">
               		<span v-show="sending && !arrayValidation.email" class="error__form" >{{$t('FILL_EMAIL')}}</span>
            	</div>
				<div class="form__content grid-form-100">
                	<label for="tel" class="requerid">{{$t('PHONE')}}Celular</label>
               		<input name="tel" type="tel" placeholder="(DDD) 99999-9999" v-maska="'(##) #####-####'" :disabled="!isEditing" v-model="form.cellPhone">
               		<span v-show="sending && !arrayValidation.cellPhone" class="error__form" >{{$t('FILL_PHONE')}}</span>
            	</div>
				<div class="form__content grid-form-100">
                	<label for="tel" class="requerid">{{$t('INSTA')}}</label>
               		<input name="instagram" @input="verificaArr()" type="text" placeholder="@" :disabled="!isEditing" v-model="form.instagram">
               		<span v-show="sending && !arrayValidation.instagram" class="error__form" >{{$t('FILL_INSTA')}}</span>
            	</div>

				<div class="clear"></div>
				<legend>{{$t('PROFESSION')}}</legend>
				<div class="form__content grid-form-100">
                	<label for="profissao" class="">{{$t('PROFESSION')}}</label>
               		<input name="profissao" type="text" placeholder="" v-model="form.profession" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.profession" class="error__form" >Preencha sua profissão, por favor.</span> -->
            	</div>
				<div class="form__content grid-form-100">
                	<label for="cau" class="">{{$t('CAU_CFT')}}</label>
               		<input name="cau" type="text" placeholder="" v-model="form.professionalDoc" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.professionalDoc" class="error__form" >Preencha, por favor.</span> -->
            	</div>
				<div class="form__content grid-form-100">
                	<label for="tempo" class="">{{$t('WORK_TIME')}}</label>
               		<input name="tempo" type="text" placeholder="" v-model="form.workTimeExperience" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>			
				<div class="form__content toggles grid-form-30">
						<input :disabled="!isEditing" @click="inputChange($event, 'occupation')" :checked="form.occupation == 'profissional'" value="profissional" id="profissional" type="radio" name="group1" >
						<label for="profissional">{{$t('GRADUATED')}}</label>
					</div>

					<div class="form__content toggles grid-form-30">
						<input :disabled="!isEditing" @click="inputChange($event, 'occupation')" value="estudante" id="estudante" :checked="form.occupation == 'estudante'" type="radio" name="group1" >
						<label for="estudante">{{$t('STUDENT')}}</label>
					</div>
				<div class="clear"></div>	

			</form>	
			<form  id="vip"  v-if="!isPass">
				<div class="clear"></div>	
				<!-- FULL Form  ================= -->
				<legend>{{$t('ADDRESS')}}</legend>

				<div class="form__content grid-form-25">
                	<label for="tempo" class="requerid">{{$t('CEP')}}</label>
               		<input name="tempo" type="text" placeholder="" v-model="address.zipCode" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>	

				<div class="form__content grid-form-60">
                	<label for="tempo" class="requerid">{{$t('STREET')}}</label>
               		<input name="tempo" type="text" placeholder="" v-model="address.address" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>	

				<div class="form__content grid-form-15">
                	<label for="tempo" class="requerid">{{$t('NUMBER')}}</label>
               		<input name="tempo" type="text" placeholder="" v-model="address.numberAddress" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>	

				<div class="form__content grid-form-50">
                	<label for="tempo" class="requerid">{{$t('NEIGHBOORHOOD')}}</label>
               		<input name="tempo" type="text" placeholder="" v-model="address.district" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>	
				<div class="form__content grid-form-50">
                	<label for="tempo" class="requerid">{{$t('ADDRESS_2')}}</label>
               		<input name="tempo" type="text" placeholder="" v-model="address.complement" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>

				<div class="form__content grid-form-50">
                	<label for="tempo" class="requerid">{{$t('CITY')}}</label>
               		<input name="tempo" type="text" placeholder="" v-model="address.city" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>
				<div class="form__content grid-form-50">
                	<label for="tempo" class="requerid">{{$t('STATE')}}</label>
               		<input name="tempo" type="text" placeholder="" v-model="address.state" :disabled="!isEditing">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>
					
				<div class="clear"></div>

				 <div class="form__content toggles grid-form-35">
                	<input :disabled="!isEditing" @click="inputChange($event, 'professionDescription')" :checked="form.professionDescription == 'comercial'" id="comercial" type="radio" name="group3" value="comercial" >
					<label for="comercial" class="checkbox">{{$t('ARCHITECT_TYPE')}}</label>
                	<span class="error__form" v-show="sending && !arrayValidation.page2.optInArchitect">{{$t('REQUIRED_1')}}</span>
				</div>

				 <div class="form__content toggles grid-form-35">
                	<input :disabled="!isEditing" @click="inputChange($event, 'professionDescription')" :checked="form.professionDescription == 'residencial'" id="residencial" type="radio" name="group3" value="residencial" >
					<label for="residencial" class="checkbox">{{$t('ARCHITECT_TYPE1')}}</label>
                	<span class="error__form" v-show="sending && !arrayValidation.page2.optInArchitect">{{$t('REQUIRED_1')}}</span>
				</div>

				<div class="form__content toggles grid-form-25">
                	<input :disabled="!isEditing" @click="inputChange($event, 'professionDescription')" value="..." name="group3" :checked="form.professionDescription != 'residencial' && form.professionDescription != 'comercial'" id="outros" type="radio" >
					<label for="outros" class="checkbox">{{$t('ARCHITECT_TYPE_OTHER')}}</label>
                	<span class="error__form" v-show="sending && !arrayValidation.page2.optInArchitect">{{$t('REQUIRED_1')}}</span>
				</div>

				<div v-if="form.professionDescription != 'residencial' && form.professionDescription != 'comercial'" class="form__content grid-form-100">
                	<label for="outros-desc" class="requerid">{{$t('ARCHITECT_TYPE_OTHER')}}</label>
					<textarea :disabled="!isEditing" name="outros-desc" v-model="form.professionDescription" id="outros-desc"></textarea>
               		
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>
				<fieldset>
				<div class="form__content toggles grid-form-30">
                    <input :disabled="!isEditing" @click="inputChange($event, 'professionNature'); cnpj_show = true" :checked="form.professionNature == 'pj'" value="pj" id="pj" type="radio" name="group2" >
                    <label for="pj">{{$t('EMPLOYEE_TYPE_SELF')}}</label>
                </div>

				<div class="form__content toggles grid-form-30">
                    <input :disabled="!isEditing" @click="inputChange($event, 'professionNature'); cnpj_show = true" value="office" id="office" type="radio" :checked="form.professionNature == 'office'" name="group2" >
                    <label for="office">{{$t('EMPLOYEE_TYPE_OFFICE')}}</label>
                </div>
				</fieldset>
				<div v-show="cnpj_show" class="form__content grid-form-50">
                	<label for="cnpj" class="requerid">{{$t('CNPJ')}}</label>
               		<input :disabled="!isEditing" name="type" type="text" placeholder="" v-model="form.cnpj">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>
				<div class="form__content grid-form-50" v-if="form.professionNature == 'office'">
                	<label for="office-name" class="requerid">{{$t('OFFICE_NAME')}}</label>
               		<input :disabled="!isEditing" name="office-name" type="text" placeholder="" v-model="form.officeName">
               		<!-- <span v-show="sending && !arrayValidation.workTimeExperience" class="error__form" >Preencha, por favor.</span> -->
            	</div>

				 <!-- <div v-if="!formCopy.vip && !formCopy.vipRegister" class="form__content toggles grid-form-100">
					<input @change="form.optInVip==false?form.optInVipDouble=false:form.optInVipDouble=form.optInVipDouble" :disabled="!isEditing" v-model="form.optInVip" id="politica" type="checkbox">
					<label for="politica" class="checkbox">{{$t('OPTIN_TERMS_CONDITIONS_1')}}&nbsp;<a href="regulamento-lg-magenta-programa-pontos-vip-code.pdf" target="_blank" >{{$t('OPTIN_TERMS_CONDITIONS')}}</a></label> -->
					<!-- <span class="error__form" v-show="sending && !arrayValidation.page2.optInArchitect">{{$t('REQUIRED_1')}}</span> -->
            	<!-- </div>
				<div  id="vip-double"
					v-if="!formCopy.optInVipDouble" 
					class="form__content toggles grid-form-100">
					<input id="double" :disabled="!isEditing || !form.optInVip"  v-model="form.optInVipDouble" type="checkbox">
					<label for="double" class="checkbox">{{$t('OPTIN_TERMS_CONDITIONS_1')}}&nbsp;<a href="regulamento-lg-magenta-o-dobro-de-pontos.pdf" target="_blank" >{{$t('OPTIN_TERMS_CONDITIONS_2')}}</a></label> -->
					<!-- <span class="error__form" v-show="sending && !arrayValidation.page2.optInArchitect">{{$t('REQUIRED_1')}}</span> -->
            	<!-- </div> -->
			<div class="clear">

			</div>

			<!-- FULL Form  ================= -->
			</form>
			<form v-if="isPass">	
				<legend >{{$t('PASS')}}</legend>

				<div class="password__disclaimer">
					<p>{{$t('PASS_CREATE_1')}}</p>
					<ul>
						<li>{{$t('PASS_CREATE_2')}}</li>
						<li>{{$t('PASS_CREATE_3')}}</li>
						<li>{{$t('PASS_CREATE_4')}}</li>
						<li>{{$t('PASS_CREATE_5')}}</li>
					</ul>
				</div>
				<!-- <div class="form__content grid-form-100">
                	<label for="oldSenha">Senha Antiga</label>
               		<input name="oldSenha" type="text" placeholder="" v-model="form.oldPassword" :disabled="!isEditing">
               		<span v-show="sending && !arrayValidation.profession" class="error__form" >Preencha sua profissão, por favor.</span>
            	</div> -->
				<div class="form__content grid-form-100">
                	<label for="newPass" class="requerid">{{$t('NEW_PASS')}}</label>
               		<input name="newPass" placeholder="" :type="showPass?'text':'password'" v-model="form.password" :disabled="!isPass">
                    <i class="bi bi-eye-slash" @click="showPass = !showPass"></i>
               		<span v-show="sending && !arrayValidation.password" class="error__form" >{{$t('PASS_REQUIRED')}}</span>
            	</div>
				<div class="form__content grid-form-100">
                	<label for="newPassConfirm" class="requerid">{{$t('PASS_REQUIRED_2')}}</label>
               		<input name="newPassConfirm" :type="showPassConfirm?'text':'password'" placeholder="" v-model="form.passwordConfirm" :disabled="!isPass">
					<i class="bi bi-eye-slash" @click="showPassConfirm = !showPassConfirm"></i>
               		<span v-show="sending && !arrayValidation.passwordConfirm" class="error__form" >{{$t('PASS_ERROR')}}</span>
            	</div>
				<div class="clear"></div>
			</form>
			<form>
				<span v-show="feedback.status != '' && feedback.message != ''" class="alert__finished">{{feedback.message}}</span>
				<button class="btn btn--form" :disabled="(!isEditing || !isFormValid) && (!isPass ||  !formPassvalid)" @click="send" type="button">{{$t('SAVE')}}</button>
			</form>
			</div>
			<!-- FORM CONTENT ========================================-->


		
		</div>
	</main>
  <!--[END] CONTEUDO =========================================== -->
  
  
	
</template>



<script>
import dt from 'date-and-time';
import EnvioNotas from './perfil/EnvioNotas.vue'
import Pontos from './perfil/Pontos.vue'


export default {
	components: {
		EnvioNotas,
		Pontos
		
	},
	data(){
		return {
			cnpj_show: false,
			showPass: false,
			showPassConfirm: false,
			sending: false,
			formCopy:{user:{}},
			user:{},
			address:{},
			isEditing: false,
			isPass: false,
			isVip: false,
			isVipDouble: false,
			other: false,
			form:{},
			feedback:{
				status: "",
				message: "",
			},
			 regex : {

				invoiceNumber : /^[a-zA-Z0-9_.-]+$/,

				imei : /^[0-9_.-]*$/,

				cnpj : /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/,

				phone : /^((\(\d{2}\) ?)|(\d{5}-))?\d{5}-\d{4}|\d{3}$/,

				email : /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

				cep : /^[0-9]{5}-?[0-9]{3}$/,

                senha : /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,

				isNumber : /\d+/g

			},
			
			activeTab: " ",
			tabsList: [
				{id: "points", label: this.$i18n.t('FORM_PONTOS') },
				{id: "dados", label: this.$i18n.t('FORM_PERFIL') },
				{id: "notas", label: this.$i18n.t('FORM_NOTA') },
			],
			
		}
		
	},
	computed : {
		isFormValid : function(){
            var ret = true
      console.log(this.arrayValidation)
            Object.entries(this.arrayValidation).forEach(([key, value]) => {
                if(!value)
                    ret = false;
            });
            return ret;
        },
		formPassvalid : function(){
			var ret = true
            Object.entries(this.arrayPassValidation).forEach(([key, value]) => {
                if(!value)
                    ret = false;
            });
            return ret;
		},
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
		arrayPassValidation : function() {
            return {
				password: !!this.form.password && this.regex.senha.test(this.form.password),
				passwordConfirm: !!this.form.passwordConfirm && this.form.password === this.form.passwordConfirm
			}
		},
		arrayValidation : function() {
      return {
				name: !!this.form.name && !!this.form.name.trim(),
				CPF: this.form.CPF === this.formCopy.cpf || (!!this.form.CPF &&  !!this.form.CPF.trim() && this.isCPF(this.form.CPF)),
				birthDate: !!this.form.birthDate && !!this.form.birthDate.trim() && this.setaData(this.form) !== '',
				email: !!this.form.email && !!this.form.email.trim() && this.regex.email.test(this.form.email),
				cellPhone: !!this.form.cellPhone && !!this.form.cellPhone.trim() && this.form.cellPhone.length !== 11,
				instagram: !!this.form.instagram &&
					 this.form.instagram.length > 1
					&& this.form.instagram.indexOf("@") == 0,
        zipCode: !!this.address.zipCode  && this.address.zipCode.length > 1,
        address: !!this.address.address  && this.address.address.length > 1,
        number: !!this.address.numberAddress  && this.address.numberAddress.length > 1,
        district: !!this.address.district  && this.address.district.length > 1,
        complement: !!this.address.complement  && this.address.complement.length > 1,
        city: !!this.address.city  && this.address.city.length > 1,
        state: !!this.address.state  && this.address.state.length > 1,
        cnpj: !!this.form.cnpj  && this.form.cnpj.length > 1,
        officeName: this.form.professionNature == 'pj' || (!!this.form.officeName  && this.form.officeName.length > 1),
        optInVip: !this.isVip || (this.isVip && this.form.optInVip),
		optInVipDouble: !this.isVipDouble || (this.isVipDouble && this.form.optInVipDouble)
      }
    }
	},
	created() {
		this.activeTab = this.tabsList[0].id;

		if (!this.loggedIn) {
			this.$router.push("/login");
			return;
			}
			document.title= 'Perfil '+(this.$store.state.auth?.user?.name?this.$store.state.auth?.user?.name:'')+' | ' + document.title.substring(document.title.indexOf('|') + 1)
			this.init();
	},
  methods : {
	
	verificaArr(){
		if(!(this.form.instagram && this.form.instagram.indexOf("@") == 0))
			this.form.instagram = '@'+(!!this.form.instagram?this.form.instagram:'');
	},
	  init(){
		  this.isEditing = false;
		  this.isPass = false;
		  this.sending = false;
		  this.isVip = false;
		  this.isVipDouble = false;
		  this.$store.dispatch("part/getInfo").then(
			response => {
				this.buildForm(response.data);
				this.formCopy = response.data;
			}, 
			error =>{
				this.feedback.status = "ERRO"
				this.feedback.message = error.response.data.message
			}).catch((erro) =>{
				this.feedback.status = "ERRO"
				if(erro.hasOwnProperty('response'))
					if(erro.response.hasOwnProperty('data'))
						this.feedback.message = erro.response.data.message;  
					else
						this.feedback.message = erro.response.message;
				else     
					this.feedback.message = "Erro ao Recuperar Dados do Usuário!"
			});
	  },
	  buildForm(resp){
		this.form.name= resp.user.name,
		this.form.email= resp.user.email,
		this.form.password= "",
		this.form.passwordConfirm= "",
		this.form.birthDate= this.formatData(resp.birthDate),
		this.form.CPF= resp.cpf,
		this.form.cellPhone= resp.cellPhone,
		this.form.instagram= resp.instagram,
		this.verificaArr();
		this.form.codOBS= resp.codOBS,
		this.form.influencer= resp.influencer,
		this.address= resp.address;
		this.form.professionalDoc= resp.professionalDoc,
		this.form.profession= resp.profession,
		this.form.professionalProfile= resp.professionalProfile,
		this.form.workspace= resp.workspace,
		this.form.workTimeExperience= resp.workTimeExperience,
		this.form.howDidYouGet= resp.howDidYouGet,
		this.form.imageDoc= resp.imageDoc,
		this.form.imageNameDoc= resp.imageNameDoc
		this.form.occupation= resp.occupation
		this.form.professionDescription= resp.professionDescription
		this.form.professionType= resp.professionType
		this.form.professionNature= resp.professionNature
		this.form.cnpj= resp.cnpj
		this.form.officeName= resp.officeName
		this.form.optInVip= resp.optInVip
		this.form.optInVipDouble= resp.optInVipDouble
		this.address = resp.address.length>0?resp.address[0]:{};
	  },
	  formatData(dta){
		  	if(!dta)
				return "";
			var dat = dt.parse(dta, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
			return dt.format(dat, 'DD/MM/YYYY'); 
		},
		setaData(validForm){
			try{
				return dt.format(dt.parse(validForm.birthDate, "DD/MM/YYYY"), 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
			}catch(e){
				return ''
			}
		},
	  send : function(){
		  this.sending = true;
		  if(this.isFormValid && this.isEditing){
			  var formbd = {}
			  Object.assign(formbd, this.form);
			 formbd.birthDate = this.setaData(formbd); 
			 formbd.address = this.address;
			  console.log(formbd);
				  delete formbd.password;
				  delete formbd.passwordConfirm;

			  delete formbd.CPF;

        if(this.formCopy.vip || this.formCopy.vipRegister)
          delete formbd.optInVip;

		if(this.formCopy.optInVipDouble || this.formCopy.vipRegister)
          delete formbd.optInVipDouble;

			  if(!formbd.profession)
			  	delete formbd.profession;
			if(!formbd.workTimeExperience)
			  	delete formbd.workTimeExperience;
			if(!formbd.professionalDoc)
			  	delete formbd.professionalDoc;

			  this.$store.dispatch("part/updatePart", formbd).then(
					response => {
						if(response.status === 200){
							this.feedback.status = "SUCESSO"
							this.feedback.message = "Atualizado com sucesso!"
							this.init();
						}
					}, 
					error =>{
						this.feedback.status = "ERRO"
						this.feedback.message = error.response.data.message
					}).catch((erro) =>{
						this.feedback.status = "ERRO"
						if(erro.hasOwnProperty('response'))
							if(erro.response.hasOwnProperty('data'))
								this.feedback.message = erro.response.data.message;  
							else
								this.feedback.message = erro.response.message;
						else     
							this.feedback.message = "Erro ao atualizar!"
					});

				return;	
		  }

		  if(this.isPass && this.formPassvalid){
			  var formbd = {}
			  formbd.password = this.form.password
			  formbd.passwordConfirm = this.form.passwordConfirm

			  this.$store.dispatch("part/updatePart", formbd).then(
					response => {
						if(response.status === 200){
							this.feedback.status = "SUCESSO"
							this.feedback.message = "Atualizado com sucesso!"
							this.init();
						}
					}, 
					error =>{
						this.feedback.status = "ERRO"
						this.feedback.message = error.response.data.message
					}).catch((erro) =>{
						this.feedback.status = "ERRO"
						if(erro.hasOwnProperty('response'))
							if(erro.response.hasOwnProperty('data'))
								this.feedback.message = erro.response.data.message;  
							else
								this.feedback.message = erro.response.message;
						else     
							this.feedback.message = "Erro ao atualizar!"
					});
			  return;
		  }

			  setTimeout(function(){
					var lista = document.getElementsByClassName("error__form");
                    for (let index = 0; index < lista.length; index++) {
                        if(lista[index].offsetWidth > 0 && lista[index].offsetHeight > 0){
                            var top = lista[index].documentOffsetTop() - ( window.innerHeight / 2 );
                            window.scrollTo( 0, top );
                            //lista[index].scrollIntoView(true);
                            return;
                        }     
                    }                       
				},50);
	  },
	  inputChange(a, b){
		this.form[b] = a.target.value
	  },
	  isCPF : function (cpf) {

                cpf = cpf.toString();
                cpf = cpf.replace(/[^0-9]/g, '');
        
                if ( cpf.length !== 11 ) {
                    return false;
                } 
        
                var cod  = cpf.substr(0, 9);
                var sum  = 0;
                var cal  = 10;
        
                for (var i = 0; i < 9; i++) {
                     sum += ( cod[i] * cal-- );
                }
        
                var abs = sum % 11;
        
                if ( abs < 2 ) {
                    cod += '0';
                } else {
                    cod += ( 11 - abs );
                }
        
                sum = 0;
                cal = 11;
        
                for (var i = 0; i < 10; i++) {
                    sum += ( cod[i] * cal-- );
                }
        
                abs = sum % 11;
        
                if ( abs < 2 ) {
                    cod += '0';
                } else {
                    cod += ( 11 - abs );
                }
        
                return ( cod === cpf ) ? true : false;
            },
		
	isActiveTab(id){
		return this.activeTab === id;
	},
	setActiveTab(id){
		this.activeTab = id;
	},
			
  }
}
</script>