<template>
<!--[Start] CONTEUDO =========================================== -->
    <main class="news__detail">


        <section class="banner__detail" v-if="news.image">
            <picture :aria-label="news.title" >
                <source media="(min-width: 992px)" :srcset="apiBase+news.image">
                <source media="(max-width: 768px)" :srcset="apiBase+news.image">
                <img :src="apiBase+news.image" :alt="news.title" :title="news.title">
            </picture>	
        </section>


        <article class="copy__detail">
            <header class="news__header">
                <div class="news__header--icons">
                    <router-link class="link__back" to="/news" title="Voltar"></router-link>
                </div>
                <div class="tags">
                    <template v-for="(tag, index) in news.keyNews" :key="index">
                        <a href="#">#{{tag.value}}</a>
                    </template>  
                </div>
                <h1>{{news.title}}</h1>
                <time v-if="news.createdAt" class="date__news">{{formatData(news.createdAt)}}</time>
            </header>

            <div class="body__copy">           
                <h2>{{news.subTitle}}</h2>
                <p v-html="news.content"></p>
            </div>

        </article>


        <div v-if="false" class="author">     
            <div class="author__avatar">
                <img src="@/assets/img/avatar.jpg" alt="Nome do Autor" title="Nome do Autor">
            </div>
            <div class="author__text">
                <p>Escrito por:</p>
                <h6>{{news.title}}</h6>
                <p>Engenheiro de software e especialista de produtos LG.</p>
            </div> 
         </div>

    <!--[Start] LAST NEWS =========================================== -->
    <section class="last__news grid">
        <h3 class="col-100">{{$t('LAST_NEWS')}}</h3>
        <template v-for="(newss, index) in lastTwoNews" :key="index">
        <router-link class="box__article col-50" @click="updateScreen(newss.id)" :to="{name: 'NewsDetails', params: {id: newss.id }}">
         <!-- <a class="" :href="$router.resolve({name: 'NewsDetails', params: {id: newss.id }})"> -->
        <article class="article__list">
            <div class="article__list--img">
                <img :src="apiBase+newss.image" alt="">
            </div> 
            <div class="article__list--text">
                <div class="tags">
                    <template v-for="(tag, index) in newss.keyNews" :key="index">
                        <a href="#">#{{tag.value}}</a>
                    </template>  
                </div>
                <h1>{{newss.title}}</h1>
                <p>{{newss.subTitle}}</p>
                 <time v-if="newss.createdAt" class="article__date">{{formatData(newss.createdAt)}}</time>
             </div> 
         </article>
         </router-link>
         </template>
    </section>

    <!--[end] LAST NEWS =========================================== -->

        
    </main>
 
  

    
</template>

<script>
import dt from 'date-and-time';
import {Base64} from 'js-base64';
export default {
  data(){
        return {
            name: '',
            apiBase : process.env.VUE_APP_API_URL+'newsletter/renderimagem/',
            lastTwoNews:[],
            news : {
                extCode: "",
                title: "",
                subTitle: "",
                content: "",
                imageTumbler: "",
                image: "",
                showMainNews: true,
                keyNews: null,
                id: 0,
                active: true,
                createdAt: "",
                updatedAt: "",
                createdPublish:''
            },
        }
    }, 
  created() {
      if (!this.loggedIn) {
        this.$router.push("/login");
        }

        this.updateScreen(this.$route.params.id);
            
  document.title= document.title.substring(document.title.indexOf('|') + 1)
  },
  computed :{
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods : {
      updateScreen(id){
          this.$store.dispatch("news/allNewsFilter", id).then( 
                response => {
                    if (response.status === 200){
                        this.lastTwoNews = response.data.data;
                    }else{
                        console.log(error);
                    }    
                }, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                });      

   this.$store.dispatch("news/news", id).then( 
                response => {
                    console.log(response.data);
                    if (response.status === 200){
                        this.news = response.data;
                        if(this.news.content.indexOf('##BASE64##') >= 0){
                            this.news.content = this.news.content.replace('##BASE64##', '');  
                            this.news.content = Base64.decode(this.news.content)
                        }
                        document.title= this.news.title+' | ' + document.title.substring(document.title.indexOf('|') + 1)
                    }else{
                        console.log(response.data.message);
                    }    
                }, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                }); 
      },
      formatData(dta){
            var dat = dt.parse(dta, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
            return dat.getDate()+" de "+this.getMonth(dt.format(dat, 'M'))+" de "+dat.getFullYear(); 
        },
        getMonth(mon){
            switch(mon){
                case '1': return this.$i18n.locale=='pt'?"Janeiro":"January";
                case '2': return this.$i18n.locale=='pt'?"Fevereiro":"February";
                case '3': return this.$i18n.locale=='pt'?"Março":"March";
                case '4': return this.$i18n.locale=='pt'?"Abril":"April";
                case '5': return this.$i18n.locale=='pt'?"Maio":"May";
                case '6': return this.$i18n.locale=='pt'?"Junho":"June";
                case '7': return this.$i18n.locale=='pt'?"Julho":"July";
                case '8': return this.$i18n.locale=='pt'?"Agosto":"August";
                case '9': return this.$i18n.locale=='pt'?"Setembro":"September";
                case '10': return this.$i18n.locale=='pt'?"Outubro":"October";
                case '11': return this.$i18n.locale=='pt'?"Novembro":"November";
                case '12': return this.$i18n.locale=='pt'?"Dezembro":"December";
                default: return "";
            }
        },
  }
}
</script>


