<template>

  <!-- DIALOGE BOX =============== -->
  <Modal class="dialog__backdrop">
		<div class="dialog__container">
			<div class="dialog__container--text">
				<h3>{{$t('FORM_PONTOS_TABLE_STATUS_REJECTED_DIALOG')}}</h3>
				<p><strong>{{$t('FORM_PONTOS_TABLE_STATUS_REJECTED_DIALOG_REASON')}}</strong></p>
				<p> {{text}}</p>
			</div> 
			<div class="dialog__container--btns">
				<button type="button" class="btn--ok" @click="close">Ok</button>
			</div>		
		</div>
	</Modal>
<!-- DIALOGE BOX =============== -->

</template>

<script>
export default {
    name: 'Dialog',
	props:{
		text: String
	},
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>



