<template>
	<main class="login">  

        <div class="tittle__wrap">
            <h1>{{$t('ACCOUNT')}}</h1>
            <p>{{$t('LOGIN_DESC')}}<strong>&nbsp;{{$t('LG_MAGENTA')}}</strong>.</p>
        </div>
    
        <div class="grid login__content">
            <div class="login__content--title col-100">
                <h2>{{$t('LOGIN_1')}}</h2>
            </div>
            <div class="box__login col-50">
                <form>
                    <div class="form__content grid-form-100">
                        <label for="">{{$t('EMAIL_DIGIT')}}</label>
                        <input type="email" v-model="user.username" placeholder="">
                        <span class="error__form" v-show="!user.username && sending">{{$t('FILL_EMAIL')}}</span>
                    </div>
                    <div class="form__content grid-form-100">
                        <label for="password">{{$t('PASS_DIGIT')}}</label>
                        <input name="password" v-model="user.password" :type="showPass?'text':'password'" placeholder="" id="password">
                        <i class="bi bi-eye-slash" @click="showPass = !showPass"></i>
                        <div class="clear"></div>
                        <span class="error__form" v-show="!user.password && sending">{{$t('PASS_REQUIRED')}}</span>
                    </div>
                    <div class="clear"></div>
                    <a href="#" @click="handleReset($event)">{{$t('FORGOT_PASS')}}</a>
                    <span class="error__form" v-show="reseting">{{errorLabel.reset}}</span>
                    <hr>

                    <!-- ================================================
                    
                    ######## QUANDO PREENCHER TODOS OS CAMPOS 
                    REMOVER O "disabled"
            
                ================================================ -->       
                    <button :disabled="isDisabled" class="btn btn--form" type="button" @click="handleLogin()">{{$t('IN')}}</button>   
                    <span class="error__form" v-show="loginFail">{{errorLabel.login}}</span>               
                </form>
            </div>

            <div class="separador"></div>

            <div class="box__cadastro col-49"> 
                <h2>{{$t('NOT_CREATED')}}</h2>
                <p>{{$t('PARTICIPANT_IN')}}</p>

                <ul>
                    <li>{{$t('PARTICIPANT_IN_1')}}</li>
                    <li>{{$t('PARTICIPANT_IN_2')}}</li>
                    <li>{{$t('PARTICIPANT_IN_3')}}</li>
                    <li>{{$t('PARTICIPANT_IN_4')}} </li>
                </ul>
                <router-link class="btn btn--form" to="/cadastre-se">{{$t('REGISTER')}}</router-link> 
            </div>
        </div>
    </main>
</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      sending : false,
      reseting : false,
      loading: false,
      loginFail: false,
      resetingAux: false,
      message: "",	
      showPass: false,
	  user:{
	    username:"",
	    password:"",
	  },
	  errorLabel:{
		  login:"",
		  token:"",
		  reset:"",
		  username:"",
	  }
    };
  },
  computed: {
    isLoginValid : function(){
      return !!this.user.username || !!this.user.password;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    reseted(){
      return this.$store.state.auth.status.reset.reseted;
    },
	  isDisabled: function(){
        return !this.user.username || !this.user.username.trim() || !this.user.password || !this.user.password.trim();
    },
	  isDisabledToken: function(){
        return !this.user.username || !this.user.username.trim();
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/news");
    }

    if(this.reseted){
      this.errorLabel.reset = "Senha enviada para o email.";
      this.reseting = true;
      this.$store.state.auth.status.reset.reseted = false;
    }
    document.title= 'Login | ' + document.title.substring(document.title.indexOf('|') + 1)
  },
  methods: {
  handleReset(event){
    event.preventDefault();
    if (this.user.username.trim().length > 0) {
      this.$store.dispatch("auth/token", this.user.username).then(
        (response) => {
          if(response === "DONE"){
            this.$router.push("/forgot-password")
            this.$store.reset.auth = this.user.username;
          }
          else{
            this.errorLabel.reset = error.response.data.message;
            this.reseting = true;
          } 
        },
        (error) => {
          this.reseting = true;
          this.errorLabel.reset = error.response.data.message
			  }).catch(error => {
          this.reseting = true;
          this.errorLabel.reset = "Erro ao enviar Token"
        });
    }else {
      this.reseting = true;
      this.errorLabel.reset = "Preencha o email, por favor.";
    }
  },
	limpaErro(){
		this.errorLabel.reset ="";
		this.errorLabel.token ="";
		this.errorLabel.username = "";
		this.errorLabel.login = "";
	},
	limpaToken(){

	},
    handleLogin() {
      this.showPass = false;
      this.sending = true;
      if(!this.isLoginValid){
        this.loginFail = true;
        return;
      }
      this.$store.dispatch("auth/login", this.user).then(
        (resp) => {
          this.$router.push("/news");
          this.$i18n.locale = resp.language;
        },
        (error) => {
          this.loginFail = true;
          this.errorLabel.login = error.response.data.message
			  }).catch(error => {
          this.loginFail = true;
          this.errorLabel.login = "Erro ao enviar Token"
        });
    }
  },
};
</script>

