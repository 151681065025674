import NewsService from '../services/news.service';

const user = JSON.parse(localStorage.getItem('user-participant'));

export const news = {
  namespaced: true,
  actions: {
    allNews({ commit }, params) {
      return NewsService.allNews(params[0], params[1], params[2], params[3]);
    },

	allNewsFilter({ commit }, exclude) {
		return NewsService.allNewsFilter(exclude);
	  },

	news({ commit }, id) {
		return NewsService.news(id);
	  },

	image({ commit }, ext) {
		return NewsService.image(ext);
	  }
  },
  mutations: {}
};
