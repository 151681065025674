import api from "./api";

class ProductService {
  getModels() {
    return api.get('products/product-models');
  }

  getProducts(idModel){
    let query = '?limit=300&'
    if(idModel)
      query+='productModelId='+idModel+'&'
    return api.get('products'+query); 
  }
}

export default new ProductService();
