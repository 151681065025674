<template>
 <!--[Start] BANNER =========================================== -->
	<section class="banner__header" >						
		<div class="banner__header--content">		
			<template v-for="(main, index) in banners" :key="index">								
				<div>
					<div class="banner__text">
						<h2>{{main.title}}</h2>
						<p>{{main.legend}}</p>
						<router-link class="btn btn-slider" :to="{name: 'DownloadDetails', params: {id: main.id }}" >{{$t('READ_MORE')}}</router-link>
					</div>
					<picture :aria-label="main.title">
						<source media="(min-width: 992px)" :srcset="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'IMAGE'})?.extCode">
						<source media="(max-width: 768px)" :srcset="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'IMAGE_MOBILE'})?.extCode">
						<img :src="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'IMAGE'})?.extCode" :alt="main.title" :key="main.title">
					</picture>															
				</div>	
			</template>	         				
		</div>								
	</section>
	<!--[END] BANNER =========================================== -->

	<!--[Start] CONTEUDO =========================================== -->
	<main class="downloads__files">

		<nav class="sub__menu">
			<a href="#" class="sub--link" @click.prevent="category='BLOCOS DE PRODUTOS'" :class="{active:category==='BLOCOS DE PRODUTOS'}">BLOCOS DE PRODUTOS</a> 
			<a href="#" class="sub--link" @click.prevent="category='PDF'" :class="{active:category==='PDF'}">PDF</a>  
			<a href="#" class="sub--link" @click.prevent="category='POWER POINT'" :class="{active:category==='POWER POINT'}">POWER POINT</a>   
		</nav>

		<!--[Start] SEARCH =========================================== -->        
		<div class="search__page grid">
			<div class="search__content col-70">
				<input v-model="filtros.search" type="search" class="search" :placeholder="$t('SEARCH')">
				<button class="btn-search"></button>
			</div>
		   
		</div>
		<!--[END] SEARCH =========================================== -->


	<!--[Start] box =========================================== --> 


		<div class="content grid">
			<template v-for="(main, index) in downloads" :key="index">	
				<div class="box__download col-33">
					<!-- <img class="box__download--file" src="@/assets/img/icon-file-a.svg" alt="Tipo arquivo" title="Tipo arquivo">		 -->
					<figure>	
						<img :srcset="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'THUMB_IMAGE'})?.extCode" :alt="main.title" :title="main.title">
					</figure>			
					<div class="box__download--txt">
						<h3>{{main.title}}</h3>
						<p>{{main.legend}}</p>    
					</div>
					<div class="box__download--btn">
						<a v-if="loggedIn" class="btn__download" :href="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'UPLOAD'})?.extCode">{{$t('DOWNLOAD')}}</a>
						<router-link class="btn__download info" :to="{name: 'DownloadDetails', params: {id: main.id}}">{{$t('READ_MORE')}}</router-link>
					</div>		
				</div>
			</template>
			<!-- <div class="box__download col-33">
				<img class="box__download--file" src="@/assets/img/icon-file-ppt.svg" alt="Tipo arquivo" title="Tipo arquivo">	
				<figure>
					<img src="@/assets/img/download-cover.jpg" alt="Titulo da Matéria" title="Titulo da matéria">
				</figure>			
				<div class="box__download--txt">
					<h3>Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet</h3>
					<p>Lorem ipsum dolor sit amet</p>    
				</div>
				<div class="box__download--btn">
					<router-link class="btn__download disable" to="/">Download</router-link>
					<router-link class="btn__download info" to="/download-detail">Saiba mais</router-link>
				</div>
			</div>
			<div class="box__download col-33">
				<img class="box__download--file" src="@/assets/img/icon-file-pdf.svg" alt="Tipo arquivo" title="Tipo arquivo">	
				<figure>
					<img src="@/assets/img/download-cover.jpg" alt="Titulo da Matéria" title="Titulo da matéria">
				</figure>			
				<div class="box__download--txt">
					<h3>Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet</h3>
					<p>Lorem ipsum dolor sit amet</p>    
				</div>
				<div class="box__download--btn">
					<router-link class="btn__download disable" to="/">Download</router-link>
					<router-link class="btn__download info" to="/download-detail">Saiba mais</router-link>
				</div>
			</div> -->
		</div>
		<div class="content--more-itens center">
			<button v-if="downloads && downloads.length > 0" @click="moreDownloads()" class="btn-more" :disabled="this.filtros.skip >= this.filtros.total">{{this.filtros.skip >= this.filtros.total?$t('END_1'):$t('MORE_DOWNLOADS')}}</button>
		</div>
	<!--[END] box =========================================== -->
  </main>
</template>
<script>

export default {
 data(){
		return{
			downloads:[],
			banners:[],
			filtros:{
				order:'asc',
				skip:0,
				limit:3,
				categoria:4,
				search:'',
				sort:'co.createdAt',
				total: 0,
				typeId: 1,
				showMain: null
			},
			category:'BLOCOS DE PRODUTOS',
			observer: null,
			dont: true,
			apiBase : process.env.VUE_APP_API_URL+'contents/renderimagem/',
		}
	},	
  created() {
   document.title= 'Downloads | ' + document.title.substring(document.title.indexOf('|') + 1)
	this.getDownloads();
	this.getBanners();
  },
watch:{
	'filtros.search' : function(val){
		this.filtros.skip = 0;
		this.getDownloads();
	},
	category : function(val){
		switch(val){
			case 'BLOCOS DE PRODUTOS': this.filtros.categoria = 		4;break;
			case 'PDF': this.filtros.categoria = 		5;break;
			case 'POWER POINT': this.filtros.categoria = 6;break;
			default: this.filtros.categoria = null;break;
		}
		if(this.filtros.categoria !== null){
			this.filtros.skip = 0;
			this.getDownloads();
		}
	}
},
  mounted(){
	  var self=this;
        this.observer = new MutationObserver(function(mutations) {
            var self = this;
            if(document.getElementsByClassName('banner__text').length > 0 && self.dont){
                self.dont = false;
                self.slideTop();
            }
        }.bind(this));
        // Setup the observer
        this.observer.observe(
            document.getElementsByClassName('banner__header--content')[0],
            { attributes: true, childList: true, characterData: true, subtree: true }
        );
  },
 
	computed:{
		loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
	},

  methods: {
	  moreDownloads(){
		  this.filtros.skip +=3;
		  this.getDownloads();
	  },
	  getDownloads(){
		  this.$store.dispatch("downloads/getContents", [this.filtros.order, this.filtros.limit, this.filtros.skip, this.filtros.categoria, this.filtros.search, this.filtros.typeId, this.filtros.sort, null]).then( 
                response => {
					if(this.filtros.skip > 0)
						this.downloads.push(...response.data.data);
					else
						this.downloads = response.data.data; 
					this.filtros.total = response.data.totalCount;
				}, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                });
	  },
	  getBanners(){
		  this.$store.dispatch("downloads/getContents", ['DESC', 3, 0, null, null, this.filtros.typeId, 'co.createdAt', true]).then( 
                response => {
					this.banners = response.data.data; 
                }, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                });
	  },
	  slideTop(){
			$('.banner__header').each(function (index, sliderWrap) {
		var $sliderBanner = $(sliderWrap).find('.banner__header--content');
		var $next = $(sliderWrap).find('.next');
		var $prev = $(sliderWrap).find('.prev');
		$sliderBanner.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			// fade: true,
			autoplay: true,
			infinite: true,
			autoplaySpeed: 4000,
			cssEase: 'cubic-bezier(0.6, 0, 0.4, 1)',
			nextArrow: $next,
			prevArrow: $prev,
			arrows: false,
			dots: true,
			lazyLoad: 'progressive',
			pauseOnDotsHover: true,

			responsive: [{
				breakpoint: 992,
				settings: {
					dots: true,
					arrows: false
				}
	
			}, {
	
				breakpoint: 768,
				settings: {
					dots: true,
					arrows: false
				}
		
			}]
		});
	});
	  }
  }
  
}
</script>


