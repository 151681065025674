<template>
	<main class="about">
		<section class="banner__about">
			<img src="@/assets/img/magenta-logo-big.svg" alt="Magenta - Tendências e Benefícios">
		</section>

		<section class="about__text">
			<h1>{{$t('ABOUT')}}</h1>
			<blockquote>
				{{$t('MAGENTA_DESC')}}
				<br><br><br>
				{{$t('MAGENTA_DESC_1')}}
				<br><br><br>
				{{$t('MAGENTA_DESC_2')}}
			</blockquote>
		</section>
	</main>  
</template>

<script>
export default {
  created() {
   document.title= 'Sobre nós | ' + document.title.substring(document.title.indexOf('|') + 1)
  }
}
</script>


