import api from "./api";

class NewsService {
  getInfo(id) {
    return api.get("participants/"+id);
  }

  updatePart(id, body){
    return api.put("participants/"+id, body);
  }
}

export default new NewsService();