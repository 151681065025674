<template>
<!--[Start] CONTEUDO =========================================== -->
    <main v-if="!!main" class="detail">

        <div class="video__header">
            <video controls id="video">
                <source :src="main.linkUrl" type="video/mp4">
            </video>
        </div>

        <article class="copy__detail">
            <header class="news__header">
                <div class="news__header--icons">
                    <router-link class="link__back"  to="/videos" title="Voltar"></router-link>
                    <div class="icon--header--log">
                        <!-- <input type="checkbox" class="star--fav" > -->
                        
                         <!-- <router-link class="link__share"  to="/" title="share"></router-link> -->
                    </div>
                </div>

        
                <h1>{{main.title}}</h1>
                <time class="date__news">{{formatData(main.createdAt)}}</time>
            </header>

            <div class="body__copy" v-html="main.textContent"></div>
        </article>    


         <!--Videos relacionados  -->
            <section class="row__list">
				<h2 class="row__list--title">{{$t('VIDEO_RELATED')}}</h2>
				<div class="row__list--content">	
					<template v-for="(rel, index) in videosRel" :key="index">
					<div class="card__video">
						<router-link :to="{name: 'VideoDetail', params: {id: rel.id }}">
							<div class="card__video--thumb">
								<img class="icon--player" src="@/assets/img/player-video.png" alt="Play" title="Play">
								<img :srcset="apiBase+rel.uploads.find(function(obj){return obj.fileType?.name === 'THUMB_IMAGE'})?.extCode" :alt="rel.title" :title="rel.title">
							</div>
						</router-link>

						<!-- <div class="card__video--smallPlayer"> -->
							<!-- <i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i> -->
							<!-- <progress class="small--progress" value="0" max="100"></progress> -->
							<!-- <span class="time--smallPlayer">12:25</span> -->
						<!-- </div> -->
							
						<div class="card__video--legend">
							<p>{{rel.title}}</p>
							<input type="checkbox" class="star--fav" >
						</div>
					</div>
					</template>
			   </div>
		   </section>

    </main>
 
  

    
</template>

<script>

import dt from 'date-and-time';
export default {
 data(){
	 return{
		 main:null,
		 videosRel:[],
		 apiBase : process.env.VUE_APP_API_URL+'contents/renderimagem/',
	 }
 },
 watch: {
    $route(to, from) {
        this.getContent(this.$route.params.id);
        this.getVideosRel();
    }
  },
  created() {
    if(!this.loggedIn){
       this.$router.push('/login');
   }
    this.getContent(this.$route.params.id);
    this.getVideosRel();
  },

  computed:{
		loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
	},

  methods:{
       formatData(dta){
            var dat = dt.parse(dta, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
            return dat.getDay()+" de "+this.getMonth(dt.format(dat, 'M'))+" de "+dat.getFullYear(); 
        },
        getMonth(mon){
            switch(mon){
                case '1': return this.$i18n.locale=='pt'?"Janeiro":"January";
                case '2': return this.$i18n.locale=='pt'?"Fevereiro":"February";
                case '3': return this.$i18n.locale=='pt'?"Março":"March";
                case '4': return this.$i18n.locale=='pt'?"Abril":"April";
                case '5': return this.$i18n.locale=='pt'?"Maio":"May";
                case '6': return this.$i18n.locale=='pt'?"Junho":"June";
                case '7': return this.$i18n.locale=='pt'?"Julho":"July";
                case '8': return this.$i18n.locale=='pt'?"Agosto":"August";
                case '9': return this.$i18n.locale=='pt'?"Setembro":"September";
                case '10': return this.$i18n.locale=='pt'?"Outubro":"October";
                case '11': return this.$i18n.locale=='pt'?"Novembro":"November";
                case '12': return this.$i18n.locale=='pt'?"Dezembro":"December";
                default: return "";
            }
        },
	  getContent(id){
		  this.$store.dispatch("videos/getContent", id).then( 
                response => {
					this.main =  response.data;
                    var video = document.getElementById('video');
                    if(video)
                        video.load();
                    document.title= this.main.title+' | ' + document.title.substring(document.title.indexOf('|') + 1)
                }, 
                error =>{
					this.main =  null;
                    console.log(error);
                }).catch((erro) =>{
					this.main =  null;
                    console.log(erro);
                });
	  },
	  getVideosRel(){
		  this.$store.dispatch("videos/getContents", ['DESC', 3, 0, null, null, 2, 'co.createdAt', null]).then( 
                response => {
					this.videosRel = response.data.data; 
                }, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                });
	  }
  }
  
}


</script>


