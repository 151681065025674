<template>
 <!--[Start] BANNER =========================================== -->
	<section class="banner__header" >						
		<div class="banner__header--content">		
			<template v-for="(main, index) in banners" :key="index">									
			<div>
				<div class="banner__text">
					<h2>{{main.title}}</h2>
                    <p>{{main.legend}}</p>					
					<router-link class="btn btn-slider play"  :to="{name: 'VideoDetail', params: {id: main.id }}">{{$t('WATCH')}}</router-link>
				
				</div>
				<picture :aria-label="main.title" >
					<source media="(min-width: 992px)" :srcset="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'IMAGE'})?.extCode">
					<source media="(max-width: 768px)" :srcset="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'IMAGE_MOBILE'})?.extCode">
					<img :src="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'IMAGE'})?.extCode" :alt="main.title" :key="main.title">
				</picture>															
			</div>	
			 </template>	         				
		</div>								
	</section>
	<!--[END] BANNER =========================================== -->
	<!--[Start] CONTEUDO =========================================== -->
	<main class="videos__list">
		<nav class="sub__menu">
			<a href="#" @click.prevent="category = 'Produtos LG'" class="sub--link" :class="{active:category==='Produtos LG'}">Produtos LG</a>  
			<a href="#" @click.prevent="category = 'LG Magenta'" class="sub--link" :class="{active:category==='LG Magenta'}">LG Magenta</a>  
			
			<!-- <a href="#" @click.prevent="category = 'Favoritos'" class="sub--link" :class="{active:category==='Favoritos'}">Favoritos</a>   -->
		</nav>
		<!--[Start] SEARCH =========================================== -->        
		<div class="search__page grid">
			<div class="search__content col-70">
				<input type="search" v-model="filtros.search" class="search" :placeholder="$t('SEARCH')">
				<button class="btn-search"></button>
			</div>
		</div>
		<!--[END] SEARCH =========================================== -->
		<div class="content">
			<section class="row__list">
				<h2 class="row__list--title">{{category}}</h2>
				<div class="row__list--content">	
					<template v-for="(main, index) in videos" :key="index">
						<div class="card__video col-20">
							<router-link :to="{name: 'VideoDetail', params: {id: main.id }}">
								<div class="card__video--thumb">
									<img  class="icon--player" src="@/assets/img/player-video.png" alt="Play" title="Play">
									<img style="width: 100%;" :srcset="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'THUMB_IMAGE'})?.extCode" alt="Titulo do vídeo" title="Titulo do vídeo">
								</div>
							</router-link>
							<!-- <div class="card__video--smallPlayer">
								<i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i>
								<progress class="small--progress" value="0" max="100"></progress>
								<span class="time--smallPlayer">{{getDuration()}}</span>
							</div> -->
							<div class="card__video--legend">
								<p>{{main.title}}</p>
								<!-- <input type="checkbox" class="star--fav" > -->
							</div>
						</div>
					</template>

					<button v-if="videos && videos.length > 0" @click="moreVideos()"  class="btn__more--card" :disabled="this.filtros.skip >= this.filtros.total">{{this.filtros.skip >= this.filtros.total?'Fim':'Mais videos'}}</button>
			   </div>
		   </section>

		   <div class="content--more-itens center videos">
		  	 <button v-if="videos && videos.length > 0" @click="moreVideos()" class="btn-more" :disabled="this.filtros.skip >= this.filtros.total">{{this.filtros.skip >= this.filtros.total?'Fim':'Mais videos'}}</button>
		   </div>
		   	<section class="row__list">
				<h2 class="row__list--title">{{$t('RECOMMENDATIONS')}}</h2>
				<div class="row__list--content">	
					<template v-for="(main, index) in videosRec" :key="index">
						<div class="card__video">
							<router-link :to="{name: 'VideoDetail', params: {id: main.id }}">
								<div class="card__video--thumb">
									<img class="icon--player" src="@/assets/img/icon-play-list.svg" alt="Play" title="Play">
									<img style="width: 100%;" :srcset="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'THUMB_IMAGE'})?.extCode" alt="Titulo do vídeo" title="Titulo do vídeo">
								</div>
							</router-link>

							<!-- <div class="card__video--smallPlayer">
								<i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i>
								<progress class="small--progress" value="0" max="100"></progress>
								<span class="time--smallPlayer">12:25</span>
							</div> -->
								
							<div class="card__video--legend">
								<p>{{main.title}}</p>
								<!-- <input type="checkbox" class="star--fav" > -->
							</div>
						</div>
					</template>
			   </div>
		   </section>
		 </div>
  </main>
</template>

<script>

export default {
	data(){
		return{
			videos:[],
			videosRec:[],
			banners:[],
			filtros:{
				order:'desc',
				skip:0,
				limit:4,
				categoria:2,
				search:'',
				sort:'co.createdAt',
				total: 0,
				typeId: 2,
				showMain: null
			},
			category:'Produtos LG',
			observer: null,
			dont: true,
			
			apiBase : process.env.VUE_APP_API_URL+'contents/renderimagem/',
		}
	},	
	watch:{
		'filtros.search' : function(val){
			this.filtros.skip = 0;
			this.getVideos();
		},
		category : function(val){
			switch(val){
				// case 'Produtos LG': this.filtros.categoria = 1;break;
				case 'Produtos LG': this.filtros.categoria = 2;break;
				case 'LG Magenta': this.filtros.categoria = 3;break;
				case 'Favoritos': this.filtros.categoria = 4;break;
				default: this.filtros.categoria = null;break;
			}
			if(this.filtros.categoria !== null){
				this.filtros.skip = 0;
				this.getVideos();
			}
		}
	},
  created() {
   document.title= 'Videos | ' + document.title.substring(document.title.indexOf('|') + 1)
   this.getVideos();
   this.getBanners();
   this.getVideosRec();
//    this.getBanners();
  },

  mounted(){
	  var self=this;
        this.observer = new MutationObserver(function(mutations) {
            var self = this;
            if(document.getElementsByClassName('banner__text').length > 0 && self.dont){
                self.dont = false;
                self.slideTop();
            }
        }.bind(this));
        // Setup the observer
        this.observer.observe(
            document.getElementsByClassName('banner__header--content')[0],
            { attributes: true, childList: true, characterData: true, subtree: true }
        );
  },
 
  methods: {
	  moreVideos(){
		  this.filtros.skip +=4;
		  this.getVideos();
	  },
	  getVideos(){
		  this.$store.dispatch("videos/getContents", [this.filtros.order, this.filtros.limit, this.filtros.skip, this.filtros.categoria, this.filtros.search, this.filtros.typeId, this.filtros.sort, null]).then( 
                response => {
					if(this.filtros.skip > 0)
						this.videos.push(...response.data.data);
					else
						this.videos = response.data.data; 
					this.filtros.total = response.data.totalCount;
                }, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                });
	  },
	  getVideosRec(){
		  this.$store.dispatch("videos/getContents", ['desc', 3, 0, null, null, this.filtros.typeId, 'co.createdAt', null]).then( 
                response => {
					this.videosRec = response.data.data; 
                }, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                });
	  },
	  getBanners(){
		  this.$store.dispatch("videos/getContents", ['desc', 3, 0, null, null, this.filtros.typeId, 'co.createdAt', 1]).then( 
                response => {
					this.banners = response.data.data; 
                }, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                });
	  },
	  slideTop(){
			$('.banner__header').each(function (index, sliderWrap) {
		var $sliderBanner = $(sliderWrap).find('.banner__header--content');
		var $next = $(sliderWrap).find('.next');
		var $prev = $(sliderWrap).find('.prev');
		$sliderBanner.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			// fade: true,
			autoplay: true,
			infinite: true,
			autoplaySpeed: 4000,
			cssEase: 'cubic-bezier(0.6, 0, 0.4, 1)',
			nextArrow: $next,
			prevArrow: $prev,
			arrows: false,
			dots: true,
			lazyLoad: 'progressive',
			pauseOnDotsHover: true,

			responsive: [{
				breakpoint: 992,
				settings: {
					dots: true,
					arrows: false
				}
	
			}, {
	
				breakpoint: 768,
				settings: {
					dots: true,
					arrows: false
				}
		
			}]
		});
	});
	  }
  }
  
}
</script>


