import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import setupInterceptors from './services/setupInterceptor';
import Maska from 'maska';
import { createI18n } from 'vue-i18n'

setupInterceptors(store);

const messages = {
    en: {
        NEWS_FULL	:'Read full story',
        SEARCH	:'Search',
        ORDER_BY	:'Filter',
        START	:'Home',
        ABOUT	:'About us',
        NEWS	:'News',
        DOWNLOADS	:'Downloads',
        DOWNLOAD	:'Download',
        VIDEOS	:'Video',
        CONTACT_US	:'Contact Us',
        HELLO	:'Hello',
        READ_MORE	:'Read more',
        READ_MORE_NEWS:"LER NOTÍCIA COMPLETA",
        FULL_NAME	:'Full Name',
        EMAIL	:'E-mail',
        PHONE_CONTACT	:'Phone Number',
        DESC	:'Description',
        ATTACH	:'Attach file',
        SEND	:'Send',
        MAGENTA_DESC	:'LG Magenta is a content platform designed to bring LG and architects closer together.',
        MAGENTA_DESC_1	:'We bring you the main trends, offer master classes from renowned architects and designers, and also provide LG assets to elaborate your projects.',
        MAGENTA_DESC_2	:'In addition, by registering on our platform, you get exclusive benefits and classes for business management and branding.',
        ACCOUNT	:'Access my account',
        LOGIN_DESC	:'Enter your e-mail address and password to access your account and enjoy the benefits and exclusivities of',
        EMAIL_DIGIT	:'E-mail',
        PASS_DIGIT	:'Password',
        FORGOT_PASS	:'Forgot Password?',
        IN	:'Sign in',
        //REGISTER	:'Register now',
        PROFILE	:'My Profile',
        CPF	:'CPF',
        BORN	:'Your birthday',
        PROFESSION	:'Occupation',
        CAU_CFT	:'CAU or CFT',
        WORK_TIME	:'Time in the market',
        INSTA	:'Instagram',
        LOGOUT	:'Logout',
        EDIT_PROFILE	:'Edit Profile',
        ALTER_PASS	:'Change password',
        SAVE	:'Save',
        NEW_PASS	:'New Password',
        PROFESSION_NUMBER	:'Professional record',
        INSTA_PROFESSIONAL	:'Professional Profile (Instagram)',
        EMAIL_CONFIRM	:'Confirm e-mail',
        NEXT	:'Next',
        EXIT:'Exit',
        LOGIN:"Login {'|'} Register",
        REGISTER: 'Sign In',
        NOT_REGISTER:'Not registered',
        MAGENTA_ALTS:'LG & Magenta - Tendencias e Benefícios',
        LANG:'Language',
        LOGO_LG:'Logo LG',
        LG_MAGENTA:'LG Magenta',
        LEGAL_LG: "All rights reserved. {'|'}  © 2021 LG Electronics",
        VERSION: 'Version ',
        ENV:'Enviroment ',
        MENU:'Menu',
        SUPPORT:'Support',
        PROF:'Profile',
        CONTENTS:'Contents',
        SIGN_IN: 'Sign in to connect with exclusive contents that only participants',
        SIGN_IN_1: 'has access.',
        PERSONAL_INFO:'Personal information',
        REQUIRED: '*Required Fields',
        REQUIRED_1: 'Required Field.',
        FILL_NAME: 'Fill your name, please.',
        FILL_CPF:'Fill a valid CPF, please.',
        FILL_EMAIL:'Fill a valid Email, please.',
        FILL_FIELD:'Fill this field, please.',
        FILL_OPTION:'Select an option, please.',
        MAGENTA_INFO:'O LG Magenta é destinado exclusivamente a profissionais das áreas de arquitetura, designer de interiores e engenharia civil. Inserir número do registro: CAU, CREA, ABD ou certificado de curso das áreas mencionadas que comprovem sua formação e atuação na área.',
        PROFESSION_REASON:'Why professional record?',
        GOOGLE_TIP:'Google',
        FRIEND_TIP:'Friend indication',
        INFLUENCER_TIP:'Influencer indication',
        OTHER_TIP:'Others',
        INSTA_KEYUP:"Digit influencer instagram(Ex: {'@'}MyInfluencer)",
        PASS_CREATE:'Create a new password',
        PASS_CREATE_1:'Password must have:',
        PASS_CREATE_2:'6 or more characters',
        PASS_CREATE_3:'At least a number',
        PASS_CREATE_4:'An upper case letter',
        PASS_CREATE_5:"A especial character ({'@'}*!%:,.)",
        PASS_REQUIRED:'Digit a valid password, please.',
        PASS_REQUIRED_2:'Confirmm your password, please.',
        PASS_CONFIRM:'Confirmm password',
        LEGAL_ACCEPT:'Accept Terms and Conditions',
        PROFESSIONAL_CONFIRM:'I confirm that I am an architect, interior designer, or engineer and wish to participate in LG Magenta.',
        TERM:'Terms',
        LEGAL_LG_1:'I agree to share my data to receive information about LG products and offers based on studies of my profile. To learn more, see our ',
        LEGAL_LG_2:'privacy policy.',
        FINISH:'Finish',
        SIGN_IN_GOODBYE:'Thank you for signing up!',
        SIGN_IN_GOODBYE_1:'We are happy that you are interested in joining LG Magenta. Soon, you will receive an e-mail from us confirming your participation and you will be able to enjoy the exclusive benefits of the program.',
        SIGN_IN_GOODBYE_2:"While we validate your data, you can learn more about our products by browsing LG's website.",
        END_1:"End",
        MORE_DOWNLOADS:"More downloads",
        MORE_NEWS:"more news",
        LAST_NEWS:"Last news",
        NEWEST:'Newest',
        OLDEST:'Oldest',
        WATCH:'Watch',
        TUTO:'Tutorials',
        RECOMMENDATIONS:'Recommended for you',
        VIDEO_RELATED:'Related videos',
        PHONE:'Phone',
        PERSONAL_INFO_1:'Personal data',
        FILL_DATA:'Fill a date, please.',
        FILL_PHONE:'Fill your phone number, please.',
        FILL_INSTA:'Fill your Instagram, please.',
        CPF_CHANGE:'To change your CPF please contact support.',
        CONTACT:'Contact',
        PASS:'Password',
        PASS_ERROR: 'Passwords do not match.',
        LOGIN_1:'Login',
        NOT_CREATED:'You do not have an account yet?',
        PARTICIPANT_IN:'Some benefits of being an LG Magenta participant:',
        PARTICIPANT_IN_1:'Get first-hand access to LG releases and content',
        PARTICIPANT_IN_2:"Stay on top of the world's main trends in architecture and interior design",
        PARTICIPANT_IN_3:'Download 3D/REVIT blocks of the products to apply in your projects',
        PARTICIPANT_IN_4:'Specialized service and access to our showroom',
        HELP_CONTACT:'How we can help you?',
        HELP_CONTACT_1:'In order for us to help you, please provide some information.',
        HELP_CONTACT_2:'Send a message to our team explaining your doubt or occurrence, so that we can help you. Attach prints, photos or documents for a more complete report.',
        PASS_RECOVERY:'Recover password',
        PASS_RECOVERY_1:'For your security we send a code to your e-mail.',
        FILL_CODE:'Please confirm the code.',
        CODE_VALID:'Validate Code',
        CODE_RESEND:'Resend Code',
        MEU_VIP:'My VIP',
        ADDRESS: 'Address Information',
        CEP: 'ZIP Code',
        STREET: 'Address',
        NUMBER: 'Number',
        NEIGHBOORHOOD: 'Neighborhood',
        ADDRESS_2: 'Address 2',
        CITY: 'City',
        STATE: 'State',
        STUDENT: 'Student',
        GRADUATED: 'Graduated',
        ARCHITECT_TYPE: 'Commercial Architect',
        ARCHITECT_TYPE1: 'Residential Architect',
        ARCHITECT_TYPE_OTHER:'Others',
        EMPLOYEE_TYPE_SELF: 'Self employment',
        EMPLOYEE_TYPE_OFFICE: 'Company employment',
        CNPJ: 'Company ID (CNPJ)',
        OPTIN_TERMS_CONDITIONS: 'Points Program Terms of Use',
        OPTIN_TERMS_CONDITIONS_1: 'I agree with',
        OPTIN_TERMS_CONDITIONS_2: 'Double Points Terms of Use.',
        JOIN_POINTS: 'Join points program',
        DOUBLE_POINTS_1: 'Get',
        DOUBLE_POINTS_2: 'Double Points',
        WAITING_POINTS: 'Waiting',
        MOLA_POINTS: 'Rescue your points',

        FORM_NOTA_ALERT: 'Properly fill this field',
        FORM_NOTA_PROD_ERRO: "Error on retrivieng product informations",

        FORM_NOTA_SUCESSO: "Invoice sent successfully!",
        FORM_NOTA_ERRO: "Error on invoice sending",

        FORM_NOTA_LABEL_NOTA: 'Invoice information',
        FORM_NOTA_LABEL_PROD: 'Product information',
        FORM_NOTA_NAME: 'Invoice Customer Full Name',
        FORM_NOTA_FILE: 'Invoice (PDF, JPG or PNG)',
        FORM_NOTA_DATA: 'Purchase Date',
        FORM_NOTA_CNPJ: 'Store CNPJ',
        FORM_NOTA_INVOICE: 'Invoice number',
        FORM_NOTA_VALUE: 'Invoice value',
        FORM_NOTA_MODEL: 'Model',
        FORM_NOTA_PROD: 'Product',
        FORM_NOTA_PROD_VALUE: 'Product Value (Unit)',
        FORM_NOTA_PROD_QUANT: 'Quantity',

        FORM_NOTA_OPTIN: 'Reward Program Terms of Use',

        FORM_NOTA_TITLE: 'Upload invoice',
        FORM_NOTA_SUB_TITLE: 'Register your invoice below to turn it into points',
        FORM_NOTA: 'Send Invoice',
        FORM_NOTA_MODEL_DESC: 'Select Product Model',
        FORM_NOTA_PRODUCT_DESC: 'Select Product',

        FORM_PERFIL: 'My Profile',
        FORM_PONTOS: 'My Points',
        
        FORM_PONTOS_TITLE: 'My Points',
        FORM_PONTOS_SUB_TITLE: 'Manage and use LG Magenta reward points!',
        FORM_PONTOS_DESC: 'Reward Points Sent',
        FORM_PONTOS_TABLE: 'Last points movement',
        FORM_PONTOS_TABLE_NOTA: 'Invoice',
        FORM_PONTOS_TABLE_PRODUTO: 'Product',
        FORM_PONTOS_TABLE_QTD: 'Quantity',
        FORM_PONTOS_TABLE_DATA: 'Date',
        FORM_PONTOS_TABLE_STATUS: 'Status',
        FORM_PONTOS_TABLE_PONTOS: 'Points',
        FORM_PONTOS_TABLE_STATUS_APPROVED: 'Product Approved',
        FORM_PONTOS_TABLE_STATUS_APPROVED_DESC: 'Approved',
        FORM_PONTOS_TABLE_STATUS_ANALYSE: 'Analyse',
        FORM_PONTOS_TABLE_STATUS_ANALYSE_DESC: 'Your invoice product is being analyzed - Wait',
        FORM_PONTOS_TABLE_STATUS_REJECTED: 'Rejected',
        FORM_PONTOS_TABLE_STATUS_REJECTED_DESC: 'Invoice Product Rejected - Click to find out',
        FORM_PONTOS_TABLE_STATUS_REJECTED_DIALOG: 'Invoice Product Rejected',
        FORM_PONTOS_TABLE_STATUS_REJECTED_DIALOG_REASON: 'Reason',
      },
    pt: {
        NEWS_FULL	:'Ler noticia completa',
        SEARCH	:'Pesquisar',
        ORDER_BY	:'Ordernar por',
        START	:'Início',
        ABOUT	:'Sobre nós',
        NEWS	:'News',
        DOWNLOADS	:'Downloads',
        DOWNLOAD	:'Download',
        VIDEOS	:'Vídeos',
        CONTACT_US	:'Fale Conosco',
        HELLO	:'Olá',
        READ_MORE	:'Saiba mais',
        READ_MORE_NEWS:"LER NOTÍCIA COMPLETA",
        FULL_NAME	:'Nome completo',
        EMAIL	:'E-mail ',
        PHONE_CONTACT	:'Telefone para contato',
        DESC	:'Descrição',
        ATTACH	:'Anexar',
        SEND	:'Enviar',
        MAGENTA_DESC	:'A LG Magenta é uma plataforma de conteúdo feita para aproximar a Z e os arquitetos.',
        MAGENTA_DESC_1	:'Trazemos as principais tendências, oferecemos master classes de renomados arquitetos e designers e também disponibilizamos assets da LG para elaborar seus projetos.',
        MAGENTA_DESC_2	:'Além disso, ao se cadastrar em nossa plataforma, você conta com benefícios exclusivos e aulas para gestão de negócio e branding.',
        ACCOUNT	:'Acessar minha conta',
        LOGIN_DESC	:'Entre com seu e-mail e senha para acessar sua conta e aproveitar os benefícios e exclusividades do',
        EMAIL_DIGIT	:'Digite seu E-mail',
        PASS_DIGIT	:'Digite sua senha',
        FORGOT_PASS	:'Esqueceu a senha',
        IN	:'Entrar',
        //REGISTER	:'Registrar-se agora',
        PROFILE	:'Meu perfil',
        CPF	:'CPF',
        BORN	:'Data de nascimento',
        PROFESSION	:'Profissão',
        CAU_CFT	:'CAU ou CFT',
        WORK_TIME	:'Tempo de atuação no mercado',
        INSTA	:'Instagram',
        LOGOUT	:'Sair da conta',
        EDIT_PROFILE	:'Editar Perfil',
        ALTER_PASS	:'Alterar senha',
        SAVE	:'Salvar',
        NEW_PASS	:'Nova senha',
        PROFESSION_NUMBER	:'Registro Profissional',
        INSTA_PROFESSIONAL	:'Perfil profissional (instagram)',
        EMAIL_CONFIRM	:'Confirmar e-mail',
        NEXT	:'Avançar',
        EXIT:'Sair',
        LOGIN:"Entrar {'|'} Registre-se",
        REGISTER: 'Registre-se',
        NOT_REGISTER:'Não sou cadastrado',
        MAGENTA_ALTS:'LG & Magenta - Tendencies and Benefits',
        LANG:'Idioma',
        LOGO_LG:'Logo LG',
        LG_MAGENTA:'LG Magenta',
        LEGAL_LG: "Todos os direitos reservados. {'|'}  © 2021 LG Electronics",
        VERSION: 'Versão ',
        ENV:'Sistema de ',
        MENU:'Menu',
        SUPPORT:'Suporte',
        PROF:'Perfil',
        CONTENTS:'Conteúdo',
        SIGN_IN: 'Crie sua conta para para acessar os conteúdos exclusivos que somente participantes',
        SIGN_IN_1: 'têm acesso.',
        PERSONAL_INFO:'Informações pessoais',
        REQUIRED: '*Campos obrigatórios',
        REQUIRED_1: 'Campo obrigatorio.',
        FILL_NAME: 'Preencha seu nome, por favor.',
        FILL_CPF:'Preencha um CPF válido, por favor.',
        FILL_EMAIL:'Preencha um e-mail válido, por favor.',
        FILL_FIELD:'Preencha esse campo, por favor.',
        FILL_OPTION:'Selecione uma opção, por favor.',
        MAGENTA_INFO:'O LG Magenta é destinado exclusivamente a profissionais das áreas de arquitetura, designer de interiores e engenharia civil. Inserir número do registro: CAU, CREA, ABD ou certificado de curso das áreas mencionadas que comprovem sua formação e atuação na área.',
        PROFESSION_REASON:'Por que o registro profissional?',
        GOOGLE_TIP:'Google',
        FRIEND_TIP:'Indicação amigo(a)',
        INFLUENCER_TIP:'Indicação influenciador',
        OTHER_TIP:'Outros',
        INSTA_KEYUP:"Digite o instagram do influenciador (Ex: {'@'}MeuInfluenciador)",
        PASS_CREATE:'Crie a sua senha de acesso',
        PASS_CREATE_1:'Sua senha deve ter:',
        PASS_CREATE_2:'6 ou mais caracteres',
        PASS_CREATE_3:'Pelo menos um número',
        PASS_CREATE_4:'Um letra maiúscula',
        PASS_CREATE_5:"Um caracter especial ({'@'}*!%:,.)",
        PASS_REQUIRED:'Digite uma senha válida, por favor.',
        PASS_REQUIRED_2:'Confirme sua senha, por favor.',
        PASS_CONFIRM:'Confirmar senha',
        LEGAL_ACCEPT:'Termos e condições de aceite',
        PROFESSIONAL_CONFIRM:'Eu confirmo ser um(a) profissional da área de arquitetura, designer de interiores ou engenharia e desejo participar do LG Magenta.',
        TERM:'Termo de Uso',
        LEGAL_LG_1:'Aceito compartilhar meus dados para receber informações sobre produtos e ofertas da LG com base em estudos do meu perfil. Para saber mais, veja nossa ',
        LEGAL_LG_2:'política de privacidade.',
        FINISH:'Finalizar',
        SIGN_IN_GOODBYE:'Agradecemos pelo seu cadastro!',
        SIGN_IN_GOODBYE_1:'Ficamos felizes pelo seu interesse em fazer parte do LG Magenta. Em breve, você receberá o nosso e-mail com a confirmação da sua participação e poderá usufruir dos benefícios exclusivos do programa.',
        SIGN_IN_GOODBYE_2:'Enquanto validamos seus dados, aproveite e conheça mais sobre os nossos produtos navegando pelo site da LG.',
        END_1:"Fim",
        MORE_DOWNLOADS:"Mais Downloads",
        MORE_NEWS:"Mais notícias",
        LAST_NEWS:"Últimas notícias",
        NEWEST:'Mais recentes',
        OLDEST:'Mais antigas',
        WATCH:'Assistir',
        TUTO:'Tutoriais',
        RECOMMENDATIONS:'Recomendações pra você',
        VIDEO_RELATED:'Vídeos relacionados',
        PHONE:'Celular',
        PERSONAL_INFO_1:'Dados pessoais',
        FILL_DATA:'Preencha uma data, por favor.',
        FILL_PHONE:'Preencha seu Celular, por favor.',
        FILL_INSTA:'Preencha seu Instagram, por favor.',
        CPF_CHANGE:'Para alterar seu CPF entre em contato com o suporte.',
        CONTACT:'Contato',
        PASS:'Senha',
        PASS_ERROR: 'Senhas não estão iguais.',
        LOGIN_1:'Fazer Login',
        NOT_CREATED:'Ainda não possui uma conta?',
        PARTICIPANT_IN:'Alguns benefícios de ser um participante do LG Magenta:',
        PARTICIPANT_IN_1:'Tenha acesso a lançamentos e conteúdos da LG em primeira mão',
        PARTICIPANT_IN_2:'Fique por dentro das principais tendências da arquitetura e designer de interiores do mundo',
        PARTICIPANT_IN_3:'Faça download de blocos 3D/REVIT dos produtos para aplicar em seus projetos',
        PARTICIPANT_IN_4:'Atendimento especializado e acesso ao nosso showroom',
        HELP_CONTACT:'Como podemos te ajudar?',
        HELP_CONTACT_1:'Para que possamos te ajudar, por gentileza informe alguns dados.',
        HELP_CONTACT_2:'Envie uma mensagem para nossa equipe explicando sua dúvida ou ocorrido, para que possamos te ajudar. Anexe prints, fotos ou documentos para um relato mais completo.',
        PASS_RECOVERY:'Recuperar senha',
        PASS_RECOVERY_1:'Para a sua segurança enviamos um código para o seu e-mail.',
        FILL_CODE:'Por favor, confirme o códígo.',
        CODE_VALID:'Validar código',
        CODE_RESEND:'Reenviar código',
        MEU_VIP:'MEU VIP',
        ADDRESS: 'Endereço',
        CEP: 'CEP',
        STREET: 'Rua/Av',
        NUMBER: 'Número',
        NEIGHBOORHOOD: 'Bairro',
        ADDRESS_2: 'Complemento',
        CITY: 'Cidade',
        STATE: 'Estado',
        STUDENT: 'Estudante',
        GRADUATED: 'Profissional',
        ARCHITECT_TYPE: 'Arquiteto de trabalho comercial',
        ARCHITECT_TYPE1: 'Arquiteto de Trabalho Residencial',
        ARCHITECT_TYPE_OTHER:'Outros',
        EMPLOYEE_TYPE_SELF: 'Trabalho por conta própria',
        EMPLOYEE_TYPE_OFFICE: 'Escritório profissional',
        OFFICE_NAME:'Nome do escritório',
        CNPJ: 'CNPJ',
        OPTIN_TERMS_CONDITIONS: 'regulamento do programa de pontos.',
        OPTIN_TERMS_CONDITIONS_1: 'Li e a aceito o ',
        OPTIN_TERMS_CONDITIONS_2: 'regulamento do programa de pontos em dobro.',
        JOIN_POINTS: 'Participe do programa de pontos',
        DOUBLE_POINTS_1: 'Ganhe',
        DOUBLE_POINTS_2: 'Pontos em Dobro',
        WAITING_POINTS: 'Aguardando',
        MOLA_POINTS: 'Resgate seus pontos',


        FORM_NOTA_LABEL_NOTA: 'Dados da nota',
        FORM_NOTA_LABEL_PROD: 'Dados do produto',
        FORM_NOTA_ALERT: 'Preencher corretamente',
        FORM_NOTA_NAME: 'Nome Completo (Comprador)',
        FORM_NOTA_FILE: 'Nota Fiscal (PDF, JPG ou PNG)',
        FORM_NOTA_DATA: 'Data da compra',
        FORM_NOTA_CNPJ: 'CNPJ da Loja',
        FORM_NOTA_INVOICE: 'Nota Fiscal/Cupom',
        FORM_NOTA_VALUE: 'Valor da Nota Fiscal',
        FORM_NOTA_MODEL: 'Modelo',
        FORM_NOTA_PROD: 'Produto',
        FORM_NOTA_PROD_VALUE: 'Valor do produto (Unit.)',
        FORM_NOTA_PROD_QUANT: 'Quantidade',
        FORM_NOTA_PROD_ERRO: "Erro ao recuperar os dados do produto",

        FORM_NOTA_SUCESSO: "Envio nota fiscal efetuado com sucesso!",
        FORM_NOTA_ERRO: "Erro ao enviar nota fiscal",

        FORM_NOTA_OPTIN: 'Termos de Uso do Programa de Pontos',

        FORM_NOTA_TITLE: 'Envie suas notas fiscais',
        FORM_NOTA_SUB_TITLE: 'Envie suas notas fiscais',
        FORM_NOTA: 'Enviar Notas',
        FORM_NOTA_MODEL_DESC: 'Selecione um modelo',
        FORM_NOTA_PRODUCT_DESC: 'Selecione um produto',
        
        FORM_PERFIL: 'Meu Perfil',
        FORM_PONTOS: 'Meus Pontos',

        FORM_PONTOS_TITLE: 'Meus pontos',
        FORM_PONTOS_SUB_TITLE: 'Acompanhe e utilize seu saldo de pontos LG Magenta. Aproveite as ofertas!',
        FORM_PONTOS_DESC: 'Pontos enviados',
        FORM_PONTOS_TABLE: 'Últimas movimentações',
        FORM_PONTOS_TABLE_NOTA: 'Nota',
        FORM_PONTOS_TABLE_PRODUTO: 'Produto',
        FORM_PONTOS_TABLE_QTD: 'Qtd',
        FORM_PONTOS_TABLE_DATA: 'Data',
        FORM_PONTOS_TABLE_STATUS: 'Status',
        FORM_PONTOS_TABLE_PONTOS: 'Pontos',
        FORM_PONTOS_TABLE_STATUS_APPROVED: 'Produto aprovado',
        FORM_PONTOS_TABLE_STATUS_APPROVED_DESC: 'Aprovado',
        FORM_PONTOS_TABLE_STATUS_ANALYSE: 'Em analise',
        FORM_PONTOS_TABLE_STATUS_ANALYSE_DESC: 'Seu produto está em analise - Aguarde',
        FORM_PONTOS_TABLE_STATUS_REJECTED: 'Reprovado',
        FORM_PONTOS_TABLE_STATUS_REJECTED_DESC: 'Produto reprovado - Clique para saber mais',
        FORM_PONTOS_TABLE_STATUS_REJECTED_DIALOG: 'Sobre sua reprovação',
        FORM_PONTOS_TABLE_STATUS_REJECTED_DIALOG_REASON: 'Motivo:',
      }
  }
  const i18n = createI18n({
    locale: 'pt',
    fallbackLocale: 'en',
    messages,
  })

const app = createApp(App);
app.use(router).use(store).use(Maska).use(i18n).mount('#app');