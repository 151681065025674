import PartService from '../services/participant.service';

export const part = {
  namespaced: true,
  actions: {
    getInfo({ commit }) {			 
      var user = JSON.parse(localStorage.getItem('user-participant'));

      if(!user || !user.participant)
        return Promise.reject;
      return PartService.getInfo(user.participant);
    },

	updatePart({ commit }, body) {
    var user = JSON.parse(localStorage.getItem('user-participant'));

      if(!user || !user.participant)
        return Promise.reject;
		return PartService.updatePart(user.participant, body);
	  },
  },
  mutations: {}
};
