import SuporteService from '../services/suporte.service';

export const suporte = {
  namespaced: true,
  actions: {
    email({ commit }, form) {
      return SuporteService.email(form);
    },
  },
};
