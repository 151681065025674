import ProductService from '../services/product.service';

export const product = {
  namespaced: true,
  actions: {
    getProducts({ commit }, idModel) {
      return ProductService.getProducts(idModel);
    },

    getModels({ commit }) {
      return ProductService.getModels();
    }
  },
  mutations: {}
};
