<template>
    <main class="cadastro">  

        <div class="tittle__wrap" v-show="feedback.type !== 'SUCCESS'">
            <h1>{{$t('REGISTER')}}</h1>
            <p>{{$t('SIGN_IN')}}<strong>&nbsp;{{$t('LG_MAGENTA')}}&nbsp;</strong>{{$t('SIGN_IN_1')}}</p>
        </div>

        <progress v-show="feedback.type !== 'SUCCESS'" class="step__form" max="100" :value="progresso" ></progress>
    
        <form v-show="page == 1">

            <legend>{{$t('PERSONAL_INFO')}}</legend>
            <p class="requerid__disc">{{$t('REQUIRED')}}</p>

            <div class="form__content grid-form-100">
                <label for="nome" class="requerid">{{$t('FULL_NAME')}}</label>
                <input name="nome" v-model="form.name" type="text" placeholder="" >
                <span class="error__form" v-show="sending && !arrayValidation.page1.name">{{$t('FILL_NAME')}}</span>
            </div>

            <div class="form__content grid-form-50">
                <label for="cpf" class="requerid">{{$t('CPF')}}</label>
                <input name="cpf" type="text" v-model="form.CPF" placeholder="">
                <span class="error__form" v-show="sending && !arrayValidation.page1.CPF">{{$t('FILL_CPF')}}</span>
            </div>


            <div class="form__content grid-form-50">
                <label for="registro" class="">{{$t('PROFESSION_NUMBER')}}</label>                    
                <input v-model="form.professionalDoc" name="registro" type="text" placeholder="CAU, CREA, ABD ou certificado">
                <span class="error__form" v-show="sending && !arrayValidation.page1.professionalDoc">{{$t('FILL_FIELD')}}</span>       
                <!-- ToolTip -->
                <div class="info" :data-tip="$t('MAGENTA_INFO')">{{$t('PROFESSION_REASON')}}</div>
            </div>

            <div class="form__content grid-form-100">
                <label for="perfil" class="requerid">{{$t('INSTA_PROFESSIONAL')}}</label>
                <input v-model="form.instagram" name="perfil" @input="verificaArr()" type="text"  placeholder="@">
                <span class="error__form" v-show="sending && !arrayValidation.page1.instagram">{{$t('FILL_FIELD')}}</span>
            </div>

            <!-- <div class="form__content grid-form-100">
                <label for="upload">Imagem do registro profissional</label>
                <input v-on:change="upload($event)" type="file" id="file" name="upload" class="custom-file-input" multiple>
                <span class="error__form" v-show="sending && !arrayValidation.page1.imageDoc">Selecione um arquivo, por favor.</span>
            </div> -->

            <div class="form__content grid-form-50">
                <label for="email" class="requerid">{{$t('EMAIL')}}</label>
                <input v-model="form.email" name="email" type="email" placeholder="">
                <span class="error__form" v-show="sending && !arrayValidation.page1.email">{{$t('FILL_EMAIL')}}</span>
            </div>

            <div class="form__content grid-form-50">
                <label for="email-confir" class="requerid">{{$t('EMAIL_CONFIRM')}}</label>
                <input v-model="emailConfirm" name="email-confir" type="email" placeholder="">
                <span class="error__form" v-show="sending && !arrayValidation.page1.emailConfirm">{{$t('EMAIL_CONFIRM')}}</span>
            </div>
                
               
            <div class="clear"></div>
            <legend>{{getByLegend}}</legend>

          <div class="grid-form-100">
                <div class="form__content toggles grid-form-25">
                    <input @click="inputChange($event)" value="google" v-model="form.howDidYouGet" id="google" type="radio" name="radio" >
                    <label for="google">{{$t('GOOGLE_TIP')}}</label>
                </div>

                <div class="form__content toggles grid-form-25">
                    <input @click="inputChange($event)" value="amigo" v-model="form.howDidYouGet" id="amigo" type="radio" name="radio" >
                    <label for="amigo">{{$t('FRIEND_TIP')}}</label>

                </div>

                <div class="form__content toggles grid-form-25">
                    <input @click="inputChange($event)" value="influenciador" v-model="form.howDidYouGet" id="influenciador" type="radio" name="radio" >
                    <label for="influenciador">{{$t('INFLUENCER_TIP')}}</label>
                </div>
                <div class="form__content toggles grid-form-25">
                    <input @click="inputChange($event)" value="outros" v-model="form.howDidYouGet" id="outros" type="radio" name="radio" >
                    <label for="outros">{{$t('OTHER_TIP')}}</label>
                </div>
                <span v-show="!arrayValidation.page1.howDidYouGet && sending" class="error__form">{{$t('FILL_OPTION')}}</span>
            </div>
            <div class="clear"></div>

            <legend v-show="form.howDidYouGet !== 'google'">{{getByText}}</legend>
            <div class="form__content grid-form-100">
                <input :placeholder="form.howDidYouGet === 'influenciador'?'@':''" v-model="getByInput" v-show="!!form.howDidYouGet.trim() && form.howDidYouGet != 'google'" type="text">
                <span v-show="!arrayValidation.page1.getByError && sending && !!form.howDidYouGet.trim() && form.howDidYouGet != 'google'" class="error__form">{{$t('REQUIRED')}}</span>
                <br>
                <span v-show="!arrayValidation.page1.influenciador && sending && form.howDidYouGet === 'influenciador'" class="error__form">{{$t('INSTA_KEYUP')}}</span>

            </div>         
                <div class="clear"></div>
            <button class="btn btn--form" type="button"  @click="avanca()" >{{$t('NEXT')}}</button>

        </form>
         


        <!-- ======  SENHA ====================================-->
        <form action="" v-show="page == 2 && feedback.type !== 'SUCCESS'"> 
            <legend id="senhaMid">{{$t('PASS_CREATE')}}</legend>
        
            <div class="password__disclaimer">
                <p>{{$t('PASS_CREATE_1')}}</p>
                <ul>
                    <li>{{$t('PASS_CREATE_2')}}</li>
                    <li>{{$t('PASS_CREATE_3')}}</li>
                    <li>{{$t('PASS_CREATE_4')}}</li>
                    <li>{{$t('PASS_CREATE_5')}}</li>
                </ul>
            </div>

            <div class="form__content grid-form-50">
                <label for="password" class="requerid">{{$t('PASS_DIGIT')}}</label>
                <input v-model="form.password" name="password" :type="passShow?'text':'password'" placeholder="" id="password">
                <i :class="passShow?'bi bi-eye':'bi bi-eye-slash'" id="pass" @click="pass()"></i>
                <div class="clear"></div>
                <span class="error__form" v-show="sending && !arrayValidation.page2.password">{{$t('PASS_REQUIRED')}}</span>

            </div>
            <div class="form__content grid-form-50">
                <label for="password" class="requerid">{{$t('PASS_CONFIRM')}}</label>
                <input :type="passConfShow?'text':'password'" placeholder="" v-model="form.passwordConfirm" id="password">
                <i :class="passConfShow?'bi bi-eye':'bi bi-eye-slash'" @click="passConf()" id="togglePassword"></i>
                <span class="error__form" v-show="sending && !arrayValidation.page2.passwordConfirm">{{$t('PASS_REQUIRED_2')}}</span>
            </div>

                <div class="clear"></div>

            <legend>{{$t('LEGAL_ACCEPT')}}</legend>

            <div class="form__content toggles grid-form-100">
                <input v-model="form.optInArchitect" id="politica" type="checkbox" value="Select option" >
                <label for="politica" class="checkbox">{{$t('PROFESSIONAL_CONFIRM')}}<a href="https://www.lg.com/br/magenta/lg-magenta-termos-e-condicoes.pdf" target="_blank" >{{$t('TERM')}}</a></label>
                <span class="error__form" v-show="sending && !arrayValidation.page2.optInArchitect">{{$t('REQUIRED_1')}}</span>
            </div>

            <div class="form__content toggles grid-form-100">        
                <input v-model="form.optIn" id="aceite-compartilhar" type="checkbox">
                <label for="aceite-compartilhar" class="checkbox">{{$t('LEGAL_LG_1')}}<a href="https://www.lg.com/br/privacidade" target="_blank" >{{$t('LEGAL_LG_2')}}</a></label>              
                <span class="error__form" v-show="sending && !arrayValidation.page2.optIn">{{$t('REQUIRED_1')}}</span>
            </div>
                
                <div class="clear"></div>             
            <span class="alert__finished" v-show="feedback.type !== ''">{{feedback.message}}</span>
            <button class="btn btn--form" type="button" @click="send($event)">{{$t('FINISH')}}</button>         
        </form> 
        <form v-show="feedback.type !== ''">
            <span class="alert__finished" v-show="feedback.type !== '' && feedback.type === 'SUCCESS'">{{$t('SIGN_IN_GOODBYE')}}<br><br>{{$t('SIGN_IN_GOODBYE_1')}}<br><br>{{$t('SIGN_IN_GOODBYE_2')}}</span>
        </form>     
    </main>
</template>


<script>
import Header from '@/components/static/Header.vue'
import Footer from '@/components/static/Footer.vue'

export default{
    components:{
        Footer,
        Header,
    },
    
    data() {
        return {
            page: 1,
            details : false,
            emailConfirm: '',
            passShow:false,
            passConfShow:false,
            sending: false,
            next: false,
            getByText : "",
            getByInput:'',
            getByLegend:'Como chegou ao LG Magenta?',
            progresso:0,
            form: {
                name:"",
                CPF:'',
                professionalDoc:'',
                email:'',
                password: "",
                passwordConfirm: "",
                optIn: false,
                influencer: '',
                howDidYouGet: '',
                optInArchitect: false,
                imageDoc: '',
                imageNameDoc:'',
                instagram: '@'
            },
            file : {
				error : {
					size : false,
					type : false
				},
				image : {
					imageCode : '',
					extension : '',
					filename : ''
				}
			},
            feedback: {
                type:"",
                message:""
            },

            regex : {

				invoiceNumber : /^[a-zA-Z0-9_.-]+$/,

				imei : /^[0-9_.-]*$/,

				cnpj : /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/,

				phone : /^((\(\d{2}\) ?)|(\d{5}-))?\d{5}-\d{4}|\d{3}$/,

				email : /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

				cep : /^[0-9]{5}-?[0-9]{3}$/,

                senha : /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

			},
        };
    },
    created(){
         document.title= 'Cadastre-se | ' + document.title.substring(document.title.indexOf('|') + 1)
        if (this.loggedIn) {
            this.$router.push("/news");
        }
        Element.prototype.documentOffsetTop = function () {
            return this.offsetTop + ( this.offsetParent ? this.offsetParent.documentOffsetTop() : 0 );
        };
        console.log(Element.prototype.documentOffsetTop);
        /*if(!this.$store.state.auth.justLogged)
            this.$store.dispatch("auth/refreshTokenStart")
        this.user = this.$store.state.auth.user;*/
    },
    computed:{
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        isCompleted : function(){
            return this.page1Valid && this.page2Valid;
        },
        page1Valid : function(){
            var ret = true
            var prog = 75;
            Object.entries(this.arrayValidation.page1).forEach(([key, value]) => {
                if(!value){
                    prog -=10;
                    ret = false;
                }
            });
            this.progresso = prog;
            return ret;
        },
        page2Valid : function(){
            var ret = true
            var prog = 25;
            Object.entries(this.arrayValidation.page2).forEach(([key, value]) => {
                if(!value){
                    prog -=5;
                    ret = false;
                }
            });
            this.progresso += prog;
            return ret;
        },
        arrayValidation : function() {
            return {
                page1:{
                    name: !!this.form.name.trim(),
                    CPF:!!this.form.CPF.trim() && this.isCPF(this.form.CPF),
                    //professionalDoc: !!this.form.professionalDoc.trim(),
                    professionalDoc:true,
                    email:!!this.form.email.trim() && this.regex.email.test(this.form.email),
                    instagram: !!this.form.instagram.substring(1, this.form.instagram.length).trim() && this.form.instagram.indexOf("@") == 0,
                    emailConfirm: !!this.emailConfirm.trim() && this.emailConfirm === this.form.email,
                    getByError: this.form.howDidYouGet === 'google' || 
                        (!!this.getByInput.trim()),
                    howDidYouGet: this.form.howDidYouGet,
                    influenciador: this.form.howDidYouGet !== 'influenciador' || this.getByInput.indexOf("@") == 0,
                    //imageDoc: !!this.form.imageDoc,
                    //imageDoc:true
                },
                page2:{
                    password: !!this.form.password.trim() && (this.regex.senha.test(this.form.password)),
                    passwordConfirm: !!this.form.passwordConfirm.trim() && this.form.password === this.form.passwordConfirm,
                    optIn: this.form.optIn,
                    //howDidYouGet: this.form.,
                    optInArchitect: this.form.optInArchitect,
                }
            } 
        }
    },
    watch : {
        arrayValidation(value, old){
            this.isCompleted;
        }   
    },
    methods : {
        verificaArr(){
            if(!(this.form.instagram.indexOf("@") == 0))
                this.form.instagram = '@'+this.form.instagram;
        },
        passConf(){
            this.passConfShow = !this.passConfShow;
        },
        pass(){
            this.passShow = !this.passShow;
        },
        inputChange(e){
            if(e.target.value === 'google'){
                this.getByLegend = "Como chegou ao LG Magenta?";
                this.getByText = "";    
            }
            if(e.target.value === 'amigo'){
                this.getByLegend = "Como chegou ao LG Magenta?";
                this.getByText = "Digite o nome do seu amigo(a)";
            }
            if(e.target.value === 'influenciador'){
                this.getByLegend = "Como chegou ao LG Magenta?";
                this.getByText = "Digite um perfil de influenciador";
            }
            if(e.target.value === 'outros'){
                this.getByLegend = "Como chegou ao LG Magenta?";
                this.getByText = "Digite uma descrição";
            }
        },
        avanca(){
            this.next=true;
            this.sending=true;
            var self = this;
            if(!this.page1Valid){
                setTimeout(function(){
					var lista = document.getElementsByClassName("error__form");
                    for (let index = 0; index < lista.length; index++) {
                        if(lista[index].offsetWidth > 0 && lista[index].offsetHeight > 0){
                            var top = lista[index].documentOffsetTop() - ( window.innerHeight / 2 );
                            window.scrollTo( 0, top );
                            return;
                        }     
                    }                       
				},50);
                return;
            }
            this.sending=false;    
            this.page += 1;
            var top = document.getElementById('senhaMid').documentOffsetTop() - ( window.innerHeight / 2 );
            window.scrollTo( 0, top );
        },
        retorna(){
            this.page -= 1;
        },
        send(e){
            e.preventDefault();
            this.sending = true;
            if(!this.isCompleted){
                setTimeout(function(){
					var lista = document.getElementsByClassName("error__form");
                    for (let index = 0; index < lista.length; index++) {
                        if(lista[index].offsetWidth > 0 && lista[index].offsetHeight > 0){
                            var top = lista[index].documentOffsetTop() - ( window.innerHeight / 2 );
                            window.scrollTo( 0, top );
                            //lista[index].scrollIntoView(true);
                            return;
                        }     
                    }                       
				},50);
                return;
            }

            if(this.form.howDidYouGet === 'influenciador'){
                //this.form.influencer ="";
                this.form.influencer = this.getByInput;
            }else if(this.form.howDidYouGet !== 'google')
                this.form.howDidYouGet+=" - "+this.getByInput;
            //console.log(JSON.stringify(this.form));

            this.$store.dispatch("auth/registerParticipant", this.form).then( 
                response => {
                    if (response.status === 201){
                        this.feedback.type = "SUCCESS"
                    }else{
                        this.feedback.type = "ERRO"
                        this.feedback.message = response.data.message
                    }    
                }, 
                error =>{
                    this.feedback.type = "ERRO"
                    this.feedback.message = error.response.data.message
                }).catch((erro) =>{
                    this.feedback.type = "ERRO"
                    if(erro.hasOwnProperty(response))
                        if(erro.response.hasOwnProperty(data))
                            this.feedback.message = erro.response.data.message;  
                        else
                            this.feedback.message = erro.response.message;
                    else     
                        this.feedback.message = "Erro ao cadastrar!"
                });
        },
        updateFile : function (image, name) {
				if ( image.length > 0 ) {
                    this.form.imageDoc = image;
                    this.form.imageNameDoc = name;
				} else {
					this.form.imageDoc = '';
                    this.form.imageNameDoc = '';
				}
		},
        upload : function (e) {
			
			var self     = this;
			var max      = 7000000; //200KB //4000000 4MB
			var reader   = new FileReader();
			var file     = e.target.files[0];
			var fileInfo = {};

			this.file.image.imageCode      = '';
            this.file.image.extension = '';
            this.file.image.filename          = '';
            //this.$set(self, 'inputValue', '');

            if (e.target.files.length > 0) {

				var error   = {
					size : ((file.size >= max) ? true : false),
					type : ((
						(file.type === 'image/jpg') ||
						(file.type === 'image/jpeg') ||
						(file.type === 'image/png') ||
						(file.type === 'application/x-zip-compressed') ||
						(file.type === 'application/pdf') ||
						(file.name.split('.')[1] === 'pdf')) ? false : true)
				};

				this.file.error.size = error.size;
				this.file.error.type = error.type;

				/* IF NOT ERRORS */
				if (!error.size && !error.type) {

					// reader.readAsText(file, 'UTF-8');
					reader.readAsDataURL(file);

					reader.onload = function (evt) {

						var fileString = evt.target.result;
						var filename   = file.name.split('.');

						fileInfo.name   = file.name;
						fileInfo.type   = (file.type == 'application/x-zip-compressed') ? 'image/' + ((filename[filename.length - 1] == 'ZIP') ? filename[filename.length - 2] : filename[filename.length - 1]) : file.type;
						fileInfo.size   = file.size;
						// fileInfo.size   = Math.round(file.size / 1000)+' kB';
						fileInfo.base64 = reader.result;
						fileInfo.file   = file;

						var base64Array = fileInfo.base64.split(',');
						var fileExtension = fileInfo.type.split('/');

						if ( fileExtension[1] === undefined ) {
							var ext = file.name.split('.');
							fileExtension[1] = ext[ext.length - 1];
						};

						self.file.image.imageCode = base64Array[1];
			            self.file.image.extension = fileExtension[1];
			            self.file.image.filename  = file.name;

			            /* EMIT EVENT TO PARENT */
						self.updateFile(self.file.image.imageCode, self.file.image.filename);
					}

				} else {

					e.target.files[0] = {};
					this.send = true;
				}

			} else {

				/* CLEAN FILE LIST ERROR */
				this.file.error.size = false;
				this.file.error.type = false;
			}
		},

        

            isCPF : function (cpf) {

                cpf = cpf.toString();
                cpf = cpf.replace(/[^0-9]/g, '');
        
                if ( cpf.length !== 11 ) {
                    return false;
                } 
        
                var cod  = cpf.substr(0, 9);
                var sum  = 0;
                var cal  = 10;
        
                for (var i = 0; i < 9; i++) {
                     sum += ( cod[i] * cal-- );
                }
        
                var abs = sum % 11;
        
                if ( abs < 2 ) {
                    cod += '0';
                } else {
                    cod += ( 11 - abs );
                }
        
                sum = 0;
                cal = 11;
        
                for (var i = 0; i < 10; i++) {
                    sum += ( cod[i] * cal-- );
                }
        
                abs = sum % 11;
        
                if ( abs < 2 ) {
                    cod += '0';
                } else {
                    cod += ( 11 - abs );
                }
        
                return ( cod === cpf ) ? true : false;
            },
    }
}
</script>
