class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("user-participant"));
      return user?.refresh_token;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem("user-participant"));
      return user?.access_token;
    }

    getLanguage() {
      const user = JSON.parse(localStorage.getItem("user-participant"));
      return user?.language;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem("user-participant"));
      if(user.participant.id)
        user.participant = user.participant.id;
      user.access_token = token;
      localStorage.setItem("user-participant", JSON.stringify(user));
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem("user-participant"));
    }
  
    setUser(user) {
      if(user.participant?.id)
        user.participant = user.participant.id;
      localStorage.setItem("user-participant", JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem("user-participant");
    }

    setLang(lang) {
      var us = localStorage.getItem("user-participant");
      if(us){
        us = JSON.parse(us);
        us.language = lang;
        localStorage.setItem("user-participant", JSON.stringify(us));
      }
    }
  }
  
  export default new TokenService();
  