<template>
  <Header v-show="$route.path !== '/' && $route.path !== ''"/>
  <router-view />
  <Footer v-show="$route.path !== '/' && $route.path !== ''"/>

</template>

<style lang="sass" src="@/assets/sass/app.sass"></style>

<script>

import EventBus from "./commons/EventBus";
import Header from '@/components/static/Header.vue'
import Footer from '@/components/static/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },

  data(){
    return {
      showCont : true,
    }
  },
  mounted(){
    if(this.currentUser())
      $i18n.locale = currentUser().language;
    else
      $i18n.locale = navigator.language.indexOf('pt')>=0?'pt':'en';
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
   watch: {
    '$route' (to, from) {
      if(to.path === '' || to.fullPath === '/'){
        this.showCont = false;
        window.location.href = "https://www.lg.com/br/magenta";
      }else
        this.showCont = true;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
    if(this.currentUser?.language)
      this.$i18n.locale = this.currentUser?.language;
  },
  beforeDestroy() {
    EventBus.remove("logout");
  }
  
}
</script>