const files = {
  error : {
    size : false,
    type : false
  },
  image : {
    imageCode : '',
    extension : '',
    filename : ''
  }
};

export const file = {
  namespaced: true,
  fl: files,
  actions: {
    change({ commit }, fle) {
      commit('changeFile', fle);
    },
  },
  mutations: {
    changeFile(fle) {
      fl = fle;
    },
  }
};
