<template>


	<!--[Start] CONTEUDO =========================================== -->
	<main class="masterclass__list">

        <div class="masterclass__list--header">
            <router-link class="link__back"  to="/" title="Voltar"></router-link>
            <h1>Autocad</h1>
            <div class="masterclass__list--progressbar">
                <span>16% concluído</span>
                <progress class="progress--class" max="100" value="30" ></progress>
            </div>
        </div>

    
        <div class="content grid">

            <router-link to="video-detail" class="masterclass__card col-50">
                <div class="masterclass__card--thumb">
                     <img class="icon--player" src="@/assets/img/icon-play-list.svg" alt="Play" title="Play">
                    <img src="@/assets/img/thumb-master.jpg" alt="Titulo do vídeo" title="Titulo do vídeo">
                </div>
                <div class="masterclass__card--smallPlayer">
                    <i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i>
                    <progress class="small--progress" value="30" max="100"></progress>
                    <span class="time--smallPlayer">12:25</span>
                </div>

                <div class="masterclass__card--legend">
                    <h2>Aula 1 - Utilizando o autocad</h2>
                    <time class="calendar" datetime="2001-05-15 19:00">Último acesso em: 10/10/2021</time>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
            </router-link>

            <router-link to="video-detail" class="masterclass__card col-50">
                <div class="masterclass__card--thumb">
                     <img class="icon--player" src="@/assets/img/icon-play-list.svg" alt="Play" title="Play">
                    <img src="@/assets/img/thumb-master.jpg" alt="Titulo do vídeo" title="Titulo do vídeo">
                </div>
                <div class="masterclass__card--smallPlayer">
                    <i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i>
                    <progress class="small--progress" value="30" max="100"></progress>
                    <span class="time--smallPlayer">12:25</span>
                </div>

                <div class="masterclass__card--legend">
                    <h2>Aula 1 - Utilizando o autocad</h2>
                    <time class="calendar" datetime="2001-05-15 19:00">Último acesso em: 10/10/2021</time>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
            </router-link>

            <router-link to="video-detail" class="masterclass__card col-50">
                <div class="masterclass__card--thumb">
                     <img class="icon--player" src="@/assets/img/icon-play-list.svg" alt="Play" title="Play">
                    <img src="@/assets/img/thumb-master.jpg" alt="Titulo do vídeo" title="Titulo do vídeo">
                </div>
                <div class="masterclass__card--smallPlayer">
                    <i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i>
                    <progress class="small--progress" value="30" max="100"></progress>
                    <span class="time--smallPlayer">12:25</span>
                </div>

                <div class="masterclass__card--legend">
                    <h2>Aula 1 - Utilizando o autocad</h2>
                    <time class="calendar" datetime="2001-05-15 19:00">Último acesso em: 10/10/2021</time>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
            </router-link>

            <router-link to="video-detail" class="masterclass__card col-50">
                <div class="masterclass__card--thumb">
                     <img class="icon--player" src="@/assets/img/icon-play-list.svg" alt="Play" title="Play">
                    <img src="@/assets/img/thumb-master.jpg" alt="Titulo do vídeo" title="Titulo do vídeo">
                </div>
                <div class="masterclass__card--smallPlayer">
                    <i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i>
                    <progress class="small--progress" value="30" max="100"></progress>
                    <span class="time--smallPlayer">12:25</span>
                </div>

                <div class="masterclass__card--legend">
                    <h2>Aula 1 - Utilizando o autocad</h2>
                    <time class="calendar" datetime="2001-05-15 19:00">Último acesso em: 10/10/2021</time>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
            </router-link>

            <router-link to="video-detail" class="masterclass__card col-50">
                <div class="masterclass__card--thumb">
                     <img class="icon--player" src="@/assets/img/icon-play-list.svg" alt="Play" title="Play">
                    <img src="@/assets/img/thumb-master.jpg" alt="Titulo do vídeo" title="Titulo do vídeo">
                </div>
                <div class="masterclass__card--smallPlayer">
                    <i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i>
                    <progress class="small--progress" value="30" max="100"></progress>
                    <span class="time--smallPlayer">12:25</span>
                </div>

                <div class="masterclass__card--legend">
                    <h2>Aula 1 - Utilizando o autocad</h2>
                    <time class="calendar" datetime="2001-05-15 19:00">Último acesso em: 10/10/2021</time>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
            </router-link>

            <router-link to="video-detail" class="masterclass__card col-50">
                <div class="masterclass__card--thumb">
                     <img class="icon--player" src="@/assets/img/icon-play-list.svg" alt="Play" title="Play">
                    <img src="@/assets/img/thumb-master.jpg" alt="Titulo do vídeo" title="Titulo do vídeo">
                </div>
                <div class="masterclass__card--smallPlayer">
                    <i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i>
                    <progress class="small--progress" value="30" max="100"></progress>
                    <span class="time--smallPlayer">12:25</span>
                </div>

                <div class="masterclass__card--legend">
                    <h2>Aula 1 - Utilizando o autocad</h2>
                    <time class="calendar" datetime="2001-05-15 19:00">Último acesso em: 10/10/2021</time>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
            </router-link>
            <router-link to="video-detail" class="masterclass__card col-50">
                <div class="masterclass__card--thumb">
                     <img class="icon--player" src="@/assets/img/icon-play-list.svg" alt="Play" title="Play">
                    <img src="@/assets/img/thumb-master.jpg" alt="Titulo do vídeo" title="Titulo do vídeo">
                </div>
                <div class="masterclass__card--smallPlayer">
                    <i class="icon--smallPlayer"><svg  viewBox="0 0 24 24"><path d="M18 9.75L5.25 1.5V22.5L18.75 14.25L19.5 12.75V11.25L18 9.75Z"/></svg></i>
                    <progress class="small--progress" value="30" max="100"></progress>
                    <span class="time--smallPlayer">12:25</span>
                </div>

                <div class="masterclass__card--legend">
                    <h2>Aula 1 - Utilizando o autocad</h2>
                    <time class="calendar" datetime="2001-05-15 19:00">Último acesso em: 10/10/2021</time>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
            </router-link>


           

            
          


        </div>
	
   
  </main>
  

	
</template>

<script>

export default {
 
  created() {
   document.title= 'MasterClass Nome da aula | ' + document.title.substring(document.title.indexOf('|') + 1)
  },

  mounted(){
	  var self=this;
	  if (document.readyState === "complete" || document.readyState === "loaded") {
	 // document is already ready to go
	 self.slideTop();
	}else
	 document.addEventListener("DOMContentLoaded", function() {
	  self.slideTop();
	 });
  },
 
  methods: {
	  slideTop(){
			$('.banner__header').each(function (index, sliderWrap) {
		var $sliderBanner = $(sliderWrap).find('.banner__header--content');
		var $next = $(sliderWrap).find('.next');
		var $prev = $(sliderWrap).find('.prev');
		$sliderBanner.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			// fade: true,
			autoplay: true,
			infinite: true,
			autoplaySpeed: 4000,
			cssEase: 'cubic-bezier(0.6, 0, 0.4, 1)',
			nextArrow: $next,
			prevArrow: $prev,
			arrows: false,
			dots: true,
			lazyLoad: 'progressive',
			pauseOnDotsHover: true,

			responsive: [{
				breakpoint: 992,
				settings: {
					dots: true,
					arrows: false
				}
	
			}, {
	
				breakpoint: 768,
				settings: {
					dots: true,
					arrows: false
				}
		
			}]
		});
	});
	  }
  }
  
}
</script>


