<template>
    
    <!-- DIALOG -->
    <Dialog v-show="isModalVisible" @close="closeModal" :text="textDialog" /> 

<!--  PONTOS ========================================-->
<div class="content--points" key="points">
   
	
    <section class="pontis--content">				
        <div class="pontis--content--main col-60 ">
            <div class="text">
                <h1>{{$t('FORM_PONTOS_TITLE')}}</h1>
                <!-- <p>Acompanha e use seu saldo de pontos LG Magenta na Livelo. Aproveite as ofertas!</p> -->
                <!-- <p>Acompanha e use seu saldo de pontos LG Magenta. Aproveite as ofertas!</p> -->
                <p>{{$t('FORM_PONTOS_SUB_TITLE')}}</p>
            </div>
            <div class="card--points">
                <div class="header">
                    <!-- <img class="logo-livelo" src="@/assets/img/logo-livelo.svg" alt="Logo Livelo" title="Logo Livelo">
                    <svg class="icon--magenta-pontos"  fill="none" viewBox="0 0 30 18"><path d="M7.674 3.667c0 2.025-1.718 3.666-3.837 3.666S0 5.692 0 3.667 1.718 0 3.837 0c2.12 0 3.837 1.642 3.837 3.667zM7.674 14.333C7.674 16.358 5.956 18 3.837 18S0 16.358 0 14.333s1.718-3.666 3.837-3.666c2.12 0 3.837 1.641 3.837 3.666zM18.837 3.667c0 2.025-1.718 3.666-3.837 3.666-2.12 0-3.837-1.641-3.837-3.666S12.88 0 15 0c2.12 0 3.837 1.642 3.837 3.667zM18.837 14.333C18.837 16.358 17.12 18 15 18c-2.12 0-3.837-1.642-3.837-3.667S12.88 10.667 15 10.667c2.12 0 3.837 1.641 3.837 3.666zM30 3.667c0 2.025-1.718 3.666-3.837 3.666-2.12 0-3.837-1.641-3.837-3.666S24.044 0 26.163 0 30 1.642 30 3.667zM26.793 14.937V18h-1.297v-3.063h-3.17V13.71h3.17v-3.044h1.297v3.044H30v1.226h-3.207z"/></svg> -->

                </div>
                <div class="pontos--content">
                    <p>{{$t('FORM_PONTOS_DESC')}}</p>
                    <p class="pontos">0 <span>pts</span></p>
                </div>

                <!-- <a class="btn--livelo" href="https://www.livelo.com.br" target="_blank" rel="noopener noreferrer"><span>Ir para livelo</span><svg fill="none" viewBox="0 0 16 16"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.667 1.933h-1.68c-1.046 0-1.569 0-1.968.204a1.866 1.866 0 00-.816.815C1 3.352 1 3.875 1 4.92v7.094c0 1.045 0 1.567.203 1.967.18.35.465.637.816.816.399.203.921.203 1.965.203h7.099c1.043 0 1.565 0 1.964-.203a1.87 1.87 0 00.816-.817c.204-.399.204-.92.204-1.964v-1.683M15 5.667V1m0 0h-4.667M15 1L8.467 7.533"/></svg> </a> -->

            </div>
        </div>
        <div class="banner-form col-40">				
            <picture aria-label="Banner" >
                <source media="(min-width: 992px)" srcset="@/assets/img/banner-points-d.jpg">
                <source media="(max-width: 768px)" srcset="@/assets/img/banner-points-m.jpg">
                <img src="@/assets/img/banner-points-d.jpg" alt="Banner" title="Banner">
            </picture>
        </div>
    </section>

    <div class="box--movimentacao">
        <h2 class="title">{{$t('FORM_PONTOS_TABLE')}}</h2>

        <div class="box--table" style="--table: 2.5fr 2fr 10% 20% 20% 1.2fr">
            <header class="table--header">
                <div class="column">{{$t('FORM_PONTOS_TABLE_NOTA')}}</div>
                <div class="column">{{$t('FORM_PONTOS_TABLE_PRODUTO')}}</div>
                <div class="column">{{$t('FORM_PONTOS_TABLE_QTD')}}</div>
                <div class="column">{{$t('FORM_PONTOS_TABLE_DATA')}}</div>
                <div class="column">{{$t('FORM_PONTOS_TABLE_STATUS')}}</div>
                <div class="column">{{$t('FORM_PONTOS_TABLE_PONTOS')}}</div>
            </header>
            <div class="table--body">
               
                <div class="table--row" v-for="invoiceProduct in invoiceProducts">
                    <div class="column nota">{{invoiceProduct.invoice.number}}</div>
                    <div class="column">{{invoiceProduct.product.name}}</div>
                    <div class="column">{{invoiceProduct.quantity}}</div>
                    <div class="column">{{parseDate(invoiceProduct.createdAt)}}</div>
                    <div class="column">
                        <span v-if="invoiceProduct.invoiceProductStatus.id === 4" class="status approved" :data-tooltip="$t('FORM_PONTOS_TABLE_STATUS_APPROVED_DESC')" data-flow="left">{{$t('FORM_PONTOS_TABLE_STATUS_APPROVED')}}</span>
                        <span v-if="invoiceProduct.invoiceProductStatus.id === 1 || invoiceProduct.invoiceProductStatus.id === 3" class="status analysis" :data-tooltip="$t('FORM_PONTOS_TABLE_STATUS_ANALYSE_DESC')" data-flow="left">{{$t('FORM_PONTOS_TABLE_STATUS_ANALYSE')}}</span>
                        <button v-if="invoiceProduct.invoiceProductStatus.id === 2" @click="showModal(invoiceProduct.reason)" class="status rejected" :data-tooltip="$t('FORM_PONTOS_TABLE_STATUS_REJECTED_DESC')" data-flow="left">{{$t('FORM_PONTOS_TABLE_STATUS_REJECTED')}}
                            <i><svg viewBox="0 0 48 48"><path fill="none" d="M0 0h48v48H0z" data-name="invisible box"/><path d="M24 2a22 22 0 1022 22A21.9 21.9 0 0024 2zm0 40a18 18 0 1118-18 18.1 18.1 0 01-18 18z"/><path d="M24 20a2 2 0 00-2 2v12a2 2 0 004 0V22a2 2 0 00-2-2z"/><circle cx="24" cy="14" r="2"/></svg></i>
                        </button>
                    </div>  

                    <!-- PRECISA IMPLEMENTAR CAMPO PONTO PARA SABER SE HÁ OU NÃO E DEPOIS RENDERIZAR  -->
                    <div class="column positive">...</div>          
                    <!-- <div class="column positive">+1350pts</div> -->
                    <!-- END -->
                </div>
                <!-- <div class="table--row">
                    <div class="column nota">Nome da nota</div>
                    <div class="column">AR Condicionado 10.0000 BTus</div>
                    <div class="column">02</div>
                    <div class="column">06/02/2023</div>
                    <div class="column">
                        <span class="status analysis" data-tooltip="Seu produto está em analise - Aguarde" data-flow="left">Em analise</span>
                    </div>            
                    <div class="column positive">...</div>
                </div>
                <div class="table--row">
                    <div class="column nota">Nome da nota</div>
                    <div class="column">AR Condicionado 10.0000 BTus</div>
                    <div class="column">02</div>
                    <div class="column">06/02/2023</div>
                    <div class="column">
                        <button @click="showModal" class="status rejected" data-tooltip="Produto reprovado - Clique para saber mais" data-flow="left">Reprovado
                            <i><svg viewBox="0 0 48 48"><path fill="none" d="M0 0h48v48H0z" data-name="invisible box"/><path d="M24 2a22 22 0 1022 22A21.9 21.9 0 0024 2zm0 40a18 18 0 1118-18 18.1 18.1 0 01-18 18z"/><path d="M24 20a2 2 0 00-2 2v12a2 2 0 004 0V22a2 2 0 00-2-2z"/><circle cx="24" cy="14" r="2"/></svg></i>
                        </button>
                    </div>
                    <div class="column positive">...</div>
                </div> -->
                

            </div>			
        </div>
    </div>
    
</div>


<!--  PONTOS ========================================-->
</template>

<script>
import App from './pontos.js'
export default App;
</script>