import { createWebHistory, createRouter } from "vue-router";
import Cadastro from "@/views/Cadastro.vue";
import Login from "@/views/Login.vue";
import Forgot from "@/views/ForgotPass.vue";
import News from "@/views/News.vue";
import NewsDetails from "@/views/NewsDetails.vue";
import About from "@/views/About.vue";
import ContactUs from "@/views/ContactUs.vue";
import Downloads from "@/views/Downloads.vue";
import DownloadDetails from "@/views/DownloadDetails.vue";
import Videos from "@/views/Videos.vue";
import VideoDetail from "@/views/VideoDetail.vue";
import MasterClass from "@/views/MasterClass.vue";
import MasterClassList from "@/views/MasterClassList.vue";
import Perfil from "@/views/Perfil.vue";
import ClassDetails from "@/views/ClassDetails.vue";


const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/inicio",
    name: "Inicio",
    children: [
      {path: '', redirect: '/news'},
    ]
  },
  {
    path: "/cadastre-se",
    name: "cadastro",
    component: Cadastro,
  },
  {
    path: "/solucoes",
    name: "Soluções",
    children: [
      {path: '', redirect: '/news'},
    ]
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/news/:id",
    name: "NewsDetails",
    component: NewsDetails,
    props: true
  },
  {
    path: "/sobre-nos",
    name: "About",
    component: About,
  },
  {
    path: "/fale-conosco",
    name: "Contact Us",
    component: ContactUs,
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: Downloads,
  },
  {
    path: "/downloads/:id",
    name: "DownloadDetails",
    component: DownloadDetails,
    props: true
  },
  {
    path: "/masterclass",
    name: "MasterClass",
    component: MasterClass,
  },
  {
    path: "/masterclass-nome-class",
    name: "MasterClass List",
    component: MasterClassList,
  },
  {
    path: "/class-detail",
    name: "MasterClass Detail",
    component: ClassDetails,
  },
  {
    path: "/videos",
    name: "Vídeos",
    component: Videos,
  },
  {
    path: "/videos/:id",
    name: "VideoDetail",
    component: VideoDetail,
    props: true
  },
  {
    path: "/suporte",
    name: "Suporte",
    children: [
      {path: '', redirect: '/news'},
    ]
  },
  {
    path: "/termos-politicas",
    name: "Termos e Politicas",
    children: [
      {path: '', redirect: '/news'},
    ]
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: Perfil,
  },
  {
    path: "/forgot-password",
    name: "Esqueceu a Senha",
    component: Forgot,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth'});
}
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/cadastre-se','/login', '/forgot-password', '/news', '/fale-conosco', '/sobre-nos', '/fale-conosco', '/perfil', '/downloads', '/videos'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user-participant');
  //const userPages = ['/']

  if (authRequired && !loggedIn) {
    if(to.path === "/")
      window.location.href = "https://www.lg.com/br/magenta";
    else
      next('/login');
  } else {
    next();
  }

});


export default router;