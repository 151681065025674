<template>
<!--[Start] CONTEUDO =========================================== -->
    <main v-if="!!main" class="detail">


        <section class="banner__detail download">
           
            <img class="file--download--banner" src="@/assets/img/icon-file-pdf.svg" alt="Tipo arquivo" title="Tipo arquivo">	
            <picture :aria-label="main.title" >
                <source media="(min-width: 992px)" :srcset="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'IMAGE'})?.extCode">>
                <source media="(max-width: 768px)" :srcset="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'IMAGE_MOBILE'})?.extCode">
                <img :src="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'IMAGE'})?.extCode" :alt="main.title" :key="main.title">
            </picture>	
        </section>
        <article class="copy__detail">
            <header class="news__header">
                 <div class="news__header--icons">
                   <router-link class="link__back"  to="/downloads" title="Voltar"></router-link>                  
                </div>
                <h1>{{main.title}}</h1>
                <time class="date__news">{{formatData(main.createdAt)}}</time>
            </header>
            <div class="body__copy">
                <div v-html="main.textContent"></div>      
                 <a v-if="loggedIn" class="btn__download big"  :href="apiBase+main.uploads.find(function(obj){return obj.fileType?.name === 'UPLOAD'})?.extCode" title="Download">
                 <span>{{$t('DOWNLOAD')}}</span></a>
            </div>
        </article>     
    </main>
</template>

<script>
import dt from 'date-and-time';
export default {
 data(){
     return{
         main:null,
         apiBase : process.env.VUE_APP_API_URL+'contents/renderimagem/',
     }
 },
  created() {
   this.getContent(this.$route.params.id);
   if(!this.loggedIn){
       this.$router.push('/login');
   }
  },

  computed:{
		loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
	},

    watch: {
    $route(to, from) {
        this.getContent(this.$route.params.id);
    }
  },

  methods:{
      formatData(dta){
            var dat = dt.parse(dta, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
            return dat.getDay()+" de "+this.getMonth(dt.format(dat, 'M'))+" de "+dat.getFullYear(); 
        },
        getMonth(mon){
            switch(mon){
                case '1': return this.$i18n.locale=='pt'?"Janeiro":"January";
                case '2': return this.$i18n.locale=='pt'?"Fevereiro":"February";
                case '3': return this.$i18n.locale=='pt'?"Março":"March";
                case '4': return this.$i18n.locale=='pt'?"Abril":"April";
                case '5': return this.$i18n.locale=='pt'?"Maio":"May";
                case '6': return this.$i18n.locale=='pt'?"Junho":"June";
                case '7': return this.$i18n.locale=='pt'?"Julho":"July";
                case '8': return this.$i18n.locale=='pt'?"Agosto":"August";
                case '9': return this.$i18n.locale=='pt'?"Setembro":"September";
                case '10': return this.$i18n.locale=='pt'?"Outubro":"October";
                case '11': return this.$i18n.locale=='pt'?"Novembro":"November";
                case '12': return this.$i18n.locale=='pt'?"Dezembro":"December";
                default: return "";
            }
        },
      getContent(id){
		  this.$store.dispatch("downloads/getContent", id).then( 
                response => {
					this.main =  response.data;
                    document.title= this.main.title+' | ' + document.title.substring(document.title.indexOf('|') + 1);
                }, 
                error =>{
					this.main =  null;
                    console.log(error);
                }).catch((erro) =>{
					this.main =  null;
                    console.log(erro);
                });
	  },
  }
}
</script>


