import api from "./api";

class InvoiceService {
  getProductInvoices() {
    let query = '?order=invoiceId&offset=0&limit=10&direction=DESC'
    return api.get('invoices/invoice-products'+query);
  }

  postInvoice(body){
    return api.post('invoices', body); 
  }
}

export default new InvoiceService();
