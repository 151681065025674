<template>

 <!--[Start] BANNER =========================================== -->
    <section class="banner__header" >						
        <div class="banner__header--content">	
            <template v-for="(main, index) in listaNewsMain" :key="index">									
            <div>
                <div class="banner__text">
                    <h2>{{main.title}}</h2>
                    <p>{{main.subTitle}}</p>

                    <router-link class="btn btn-slider"  :to="{name: 'NewsDetails', params: {id: main.id }}" >{{$t('READ_MORE_NEWS')}}</router-link>
                </div>
                <picture :aria-label="main.title">
                        <source media="(min-width: 992px)" :srcset="apiBase+main.image">
                        <source media="(max-width: 768px)" :srcset="apiBase+main.imageTumbler">
                        <img :src="apiBase+main.image" :alt="main.title" :key="main.title">
                    </picture>													
            </div>	   
             </template>	      				
        </div>								
    </section>
    <!--[END] BANNER =========================================== -->

    <!--[Start] CONTEUDO =========================================== -->
    <main class="news__list">

        <!--[Start] SEARCH =========================================== -->        
        <div class="search__page grid">
            <div class="search__content col-70">
                <input @input="isVazio()" @keyup="filtra" v-model="filtraNews" type="search" class="search" :placeholder="$t('SEARCH')">
                <button class="btn-search"></button>
            </div>
            <div class="search__content col-30">
                <select @change="lista(true)" name="" id="" v-model="order" class="select">
                    <option selected disabled value=''>{{$t('ORDER_BY')}}</option>
                    <option value="DESC">{{$t('NEWEST')}}</option>
                    <option value="ASC">{{$t('OLDEST')}}</option>
                    <!-- <option value="ACESSO">Mais acessadas</option>
                    <option value="RELE">Relevantes</option> -->
                </select>
              
            </div>
        </div>
        <!--[END] SEARCH =========================================== -->


    <!--[Start] Articles =========================================== --> 
    <template v-for="(newss, index) in listaNewsFilter" :key="index">
        <router-link class="box__article" :to="{name: 'NewsDetails', params: {id: newss.id }}">
            <article class="article__list">
            <div class="article__list--img">
                <img :src="apiBase+newss.image" :alt="newss.title" :title="newss.title">
            </div> 
            <div class="article__list--text">
                <div class="tags">
                    <template v-for="(tag, index) in newss.keyNews" :key="index">
                        <a href="#">#{{tag.value}}</a>
                    </template>
                </div>
                <h1>{{newss.title}}</h1>
                    <p>{{newss.subTitle}}</p> 
                    <!-- <a href="news-detail.html" class="more__news">Leia mais...</a> -->
                    <!-- <router-link class="btn btn-slider"  to="/news-detail" >LER NOTÍCIA COMPLETA -->
                <time class="article__date">{{formatData(newss.createdAt)}}</time>
                </div> 
            </article>
        </router-link>
    </template>
    <div class="content--more-itens center">
        <button @click="moreNews()" class="btn-more" :disabled="this.serviceFilter.skip >= this.serviceFilter.total">{{this.serviceFilter.skip >= this.serviceFilter.total?$t('END_1'):$t('MORE_NEWS')}}</button>

    </div>
    <!--[END] Articles =========================================== -->      
  </main>
  

    
</template>

<script>
import dt from 'date-and-time';
export default {
    data(){
        return {
            order: '',
            filtraNews: '',
            apiBase : process.env.VUE_APP_API_URL+'newsletter/renderimagem/',
            keyNews :{
                value: "",
                newsletter: "",
                id: 0,
                active: true,
                createdAt: "",
                updatedAt: ""
            },
            news : {
                extCode: "",
                title: "",
                subTitle: "",
                content: "",
                imageTumbler: "",
                image: "",
                showMainNews: true,
                keyNews: [this.keyNews],
                id: 0,
                active: true,
                createdAt: "",
                updatedAt: "",
                createdPublish:''
            },
            serviceFilter:{
                skip: 0,
                limit: 5,
                total: null
            },
            listaNews:[],
            listaNewsFilter:[],
            listaNewsMain:[],
            observer: null,
            dont: true,
        }
    },
    created() {
        this.lista();
        document.title= 'News | ' + document.title.substring(document.title.indexOf('|') + 1)
    },

    mounted(){
        var self=this;
        this.observer = new MutationObserver(function(mutations) {
            var self = this;
            if(document.getElementsByClassName('banner__text').length > 0 && self.dont){
                self.dont = false;
                self.slideTop();
            }
        }.bind(this));
        // Setup the observer
        this.observer.observe(
            document.getElementsByClassName('banner__header--content')[0],
            { attributes: true, childList: true, characterData: true, subtree: true }
        );
    },
    
    methods: {
        moreNews(){
            if(this.serviceFilter.skip >= this.serviceFilter.total)
                return;
             this.$store.dispatch("news/allNews", [this.order, this.serviceFilter.limit, this.serviceFilter.skip]).then( 
                response => {
                    if (response.status === 200){
                        this.serviceFilter.skip +=this.serviceFilter.limit
                        this.serviceFilter.total = response.data.totalCount
                        this.listaNews.push(...response.data.data);
                        //this.listaNewsFilter.push(...response.data.data);
                    }else{
                        console.log(error);
                    }    
                }, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                });
        },
        lista(update){
            this.$store.dispatch("news/allNews", [this.order, this.serviceFilter.limit, this.serviceFilter.skip]).then( 
                response => {
                    if (response.status === 200){
                        this.serviceFilter.skip +=this.serviceFilter.limit
                        this.serviceFilter.total = response.data.totalCount
                        this.listaNews = response.data.data;
                        var listaBan = this.listaNews.filter(function(obj){return obj.showMainNews});
                        this.listaNewsFilter = response.data.data;
                        if(!update)
                            for (let index = 0; index < listaBan.length; index++) {
                                if(index > 2)
                                    break;
                                this.listaNewsMain.push(listaBan[index]);
                            }
                    }else{
                        console.log(error);
                    }    
                }, 
                error =>{
                    console.log(error);
                }).catch((erro) =>{
                    console.log(erro);
                });
        },
        filtra(){
            if(this.filtraNews){
                this.listaNewsFilter = this.listaNews.filter(this.isKindOf);
            }else
                this.listaNewsFilter = this.listaNews;
        },
        isVazio(){
            if(!this.filtraNews || this.filtraNews.length <= 0)
                this.listaNewsFilter = this.listaNews;
        },
        isKindOf(obj){
            return obj.title.toLowerCase().indexOf(this.filtraNews.toLowerCase()) >= 0 || obj.subTitle.toLowerCase().indexOf(this.filtraNews.toLowerCase()) >= 0;  
        },
        formatData(dta){
            var response = ''
            try{
                var dat = dt.parse(dta, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
                response = dat.getDate()+" de "+this.getMonth(dt.format(dat, 'M'))+" de "+dat.getFullYear(); 
            }catch(e){

            }
            return response; 
        },
        getMonth(mon){
            switch(mon){
                case '1': return this.$i18n.locale=='pt'?"Janeiro":"January";
                case '2': return this.$i18n.locale=='pt'?"Fevereiro":"February";
                case '3': return this.$i18n.locale=='pt'?"Março":"March";
                case '4': return this.$i18n.locale=='pt'?"Abril":"April";
                case '5': return this.$i18n.locale=='pt'?"Maio":"May";
                case '6': return this.$i18n.locale=='pt'?"Junho":"June";
                case '7': return this.$i18n.locale=='pt'?"Julho":"July";
                case '8': return this.$i18n.locale=='pt'?"Agosto":"August";
                case '9': return this.$i18n.locale=='pt'?"Setembro":"September";
                case '10': return this.$i18n.locale=='pt'?"Outubro":"October";
                case '11': return this.$i18n.locale=='pt'?"Novembro":"November";
                case '12': return this.$i18n.locale=='pt'?"Dezembro":"December";
                default: return "";
            }
        },
        slideTop(){
                $('.banner__header').each(function (index, sliderWrap) {
            var $sliderBanner = $(sliderWrap).find('.banner__header--content');
            var $next = $(sliderWrap).find('.next');
            var $prev = $(sliderWrap).find('.prev');
            $sliderBanner.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                // fade: true,
                autoplay: true,
                infinite: true,
                autoplaySpeed: 4000,
                cssEase: 'cubic-bezier(0.6, 0, 0.4, 1)',
                nextArrow: $next,
                prevArrow: $prev,
                arrows: false,
                dots: true,
                lazyLoad: 'progressive',
                pauseOnDotsHover: true,
                //adaptiveHeight: true,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        dots: true,
                        arrows: false
                    }
        
                }, {
        
                    breakpoint: 768,
                    settings: {
                        adaptiveHeight: true,
                        dots: true,
                        arrows: false
                    }
            
                }]
            });
        });
        }
    }
  
}
</script>


