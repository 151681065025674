<template>
	<main class="contact__us">

		<section class="banner__interno">
			<h1>{{$t('CONTACT_US')}}</h1>
			<img src="@/assets/img/banner-contato-d.jpg" alt="Titulo da Matéria" title="Titulo da matéria">
		</section>
	

		<section class="contact__content">
			<h2>{{$t('HELP_CONTACT')}}</h2>
			<p>{{$t('HELP_CONTACT_1')}}</p>

			<div v-if="loggedIn" class="whats">
				 <a href="https://wa.me/5511959669784" target="_blank"><img src="@/assets/img/icon-wwp.svg" alt="Contato via WhatsApp" title="Contato via WhatsApp"></a>
			</div>

			<form>
				  <div class="form__content grid-form-100">
					  <label for="nome" class="requerid">{{$t('FULL_NAME')}}</label>
					  <input v-model="form.name" name="nome" type="text" placeholder="" >
					  <span class="error__form" v-show="sending && !validation.nome">{{$t('FILL_NAME')}}</span>
				  </div>
				  <div class="form__content grid-form-100">
					  <label for="nome" class="requerid">{{$t('EMAIL')}}</label>
					  <input v-model="form.email" name="nome" type="text" placeholder="" >
					  <span class="error__form" v-show="sending && !validation.email">{{$t('FILL_EMAIL')}}</span>
				  </div>
				  <div class="form__content grid-form-100">
					  <label for="nome" class="requerid">{{$t('PHONE')}}</label>
					  <input v-model="form.phone" name="nome" type="text" placeholder="" >
					  <span class="error__form" v-show="sending && !validation.telefone">{{$t('FILL_PHONE')}}</span>
				  </div>
				  <div class="clear"></div>
				  <legend>{{$t('HELP_CONTACT_2')}}</legend>
		   
		   
				<div class="form__content grid-form-100">
					<label for="description" class="requerid">{{$t('DESC')}}</label>                  
					<textarea v-model="form.message" name="description" id="description" ></textarea>
					<span class="error__form" v-show="sending && !validation.mensagem">{{$t('FILL_FIELD')}}</span>
				</div>
				  
				<div class="form__content grid-form-70 upload__file--box"  style="display:">
					<!-- FILE UPLOAD ----------->
					<template v-for="(fileAux, index) in form.files" :key="index">
						<div class="file__upload">
							<div class="file__upload--file">
								<img src="@/assets/img/icon-file.svg" :alt="fileAux.fileName" :title="fileAux.fileName">
							</div>
							<div class="file__upload--progress">
								<span>{{fileAux.fileName}}</span>
								<progress class="file__document" max="100" value="100"></progress>
							</div>
							<div class="file__upload--close">
								<button class="file__remove" type="button" @click="remove(index)"></button>
							</div>
						</div>
					</template>
					<!-- FILE UPLOAD ----------->

					<!-- FILE UPLOAD ----------->
					<!-- <div class="file__upload">
						<div class="file__upload--file">
							 <img src="@/assets/img/icon-file.svg" alt="Nome do arquivo" title="Nome do arquivo">
						</div>
						<div class="file__upload--progress">
							<span>Nome do arquivo maior para testar.jpg</span>
							<progress class="file__document" max="100" value="48"></progress>
						</div>
						<div class="file__upload--close">
							<button class="file__remove"></button>
						</div>
					</div> -->
					<!-- FILE UPLOAD ----------->
				</div>
			
				<div class="form__content grid-form-30 upload__file--btn">
					<input @change="upload($event)" type="file" id="upload"  hidden/>
					<label for="upload" class="upload__contact"><span>{{$t('ATTACH')}}</span></label>
				</div>
				<div class="clear"></div>

				<span class="alert__finished" v-show="emailSend.status !== ''">{{emailSend.message}}</span>
				
		   		<div class="form__content grid-form-100 btn__contact">
					<button class="btn btn--form" type="button" @click="send">{{$t('SEND')}}</button>
				</div>
				<div class="clear"></div>
			</form>

			<!-- <div class="contat__footer">
				<p>Se preferir, entre em contato por meio do nosso canal de atendimento.</p>
				<a href="tel:+55110800 0000 000">0800 0000 000</a>
			</div> -->

			
		</section>
		
	</main>  
</template>

<script>

export default {
	data (){
		return {
			form:{
				name:'',
				email:'',
				phone:'',
				message:'',
				files: [],
			},
			file:{
				nome:'',
				base64:'',
			},
			emailSend :{
				status: '',
				message:''
			},
			sending: false
		}
	},

	computed:{
		validation :function(){
			return {
				nome: !!this.form.name && this.form.name.length > 0,
				email:!!this.form.email && this.form.email.length > 0,
				telefone: !!this.form.phone && this.form.phone.length > 0,
				mensagem: !!this.form.message && this.form.message.length > 0,
			}		
		},

		isValid : function(){
			var ret = true;
			Object.entries(this.validation).forEach(([key, value]) => {
                if(!value)
					ret = false;
            });
			return ret;
		},

		loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
	},
  created() {
   document.title= 'Fale Conosco | ' + document.title.substring(document.title.indexOf('|') + 1)
  },
  methods:{
	upload(e){
		var self = this;
		var reader   = new FileReader();
		var fl = e.target.files[0];
		reader.readAsDataURL(fl);
		reader.onload = function (evt) {
			var rs = reader.result;
			rs = rs.split(',');
			self.form.files.push({
				fileName: fl.name,
				file: rs[1],
			});
			e.target.value = '';
		}	
	},
	remove(index){
		this.form.files.splice(index, 1);
	},
	send(){
		this.sending =  true;
		console.log(this.isValid);
		console.log(this.validation);
		if(this.isValid){
			console.log(JSON.stringify(this.form));
			this.$store.dispatch("suporte/email", this.form).then( 
                response => {
                    if (response.status === 201){
                        this.emailSend.status = "SUCCESS"
						//this.emailSend.message = response.data.message;
						this.emailSend.message = 'Enviado com sucesso';
                    }else{
                        this.emailSend.status = "ERRO"
                        this.emailSend.message = response.data.message
                    }    
                }, 
                error =>{
                    this.emailSend.status = "ERRO"
                    this.emailSend.message = error.response.data.message
                }).catch((erro) =>{
                    this.emailSend.status = "ERRO"
                    if(erro.hasOwnProperty('response'))
                        if(erro.response.hasOwnProperty('data'))
                            this.emailSend.message = erro.response.data.message;  
                        else
                            this.emailSend.message = erro.response.message;
                    else     
                        this.emailSend.message = "Erro ao Enviar email!"
                });
		}else
			setTimeout(function(){
				document.getElementsByClassName("error__form")[0].scrollIntoView(false);
			},50);
	}
  }
}
</script>