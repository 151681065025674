import Dialog from '@/Dialog.vue'
import date from 'date-and-time';

export default {
    components: {
        Dialog,
    },
    data(){
        return{
            invoiceProducts:[],
            isModalVisible: false,
            // invoice: {
            //     customerFullName: '',
            //     invoiceFile: {
            //         extension: '',
            //         file: ''
            //     },
            //     cnpj: '',
            //     purchaseDate: '',
            //     number: '',
            //     amount: '',
            //     invoiceProducts: [new this.createInvoiceProduct()]
            // },
            // products: {},
            // productModels: [],
            // send: false,
            feedback: '',
            textDialog: ''
        }
    },
    created() {
        this.getProductInvoices()
	},
    computed:{},
    methods: {
        parseDate(dt){
            let d = date.parse(dt, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")

            return date.format(d, 'DD-MM-YYYY');
        },
        showModal(text) {
            this.textDialog = text
            this.isModalVisible = true;
          },
          closeModal() {
            this.isModalVisible = false;
          },
        getProductInvoices(){
            this.$store.dispatch("invoice/getProductInvoices")
            .then(
                response => {
                    console.log(response.data?.rows)
                    this.invoiceProducts = response.data?.rows    
                }, 
                error =>{
                    this.feedback = "Error on retriving Product Model Informations"
                    // console.log(error.response.rows[0].message)
                }
            ).catch((erro) =>{
                this.feedback = "Error on retriving Product Model Informations"
                console.log(erro)
            });
        },
        getProducts(modelId){
            this.$store.dispatch("product/getProducts", modelId)
            .then(
                response => {
                    console.log(response.data?.rows)
                    this.products[modelId] = response.data?.rows    
                }, 
                error =>{
                    this.feedback = "Error on retriving Products Informations"
                    // console.log(error.response.rows[0].message)
                }
            ).catch((erro) =>{
                this.feedback = "Error on retriving Products Informations"
                // console.log(erro)
            });
        }
    }
}