import VideosService from '../services/videos.service';

export const videos = {
  namespaced: true,
  actions: {
    getContents({ commit }, params) {
      return VideosService.getContents(params[0], params[1], params[2], params[3], params[4], params[5], params[6], params[7]);
    },

    getTypes({ commit }) {
      return VideosService.getTypes();
    },

    getCategories({ commit }) {
      return VideosService.getCategories();
    },

    getContent({ commit }, id){
      return VideosService.getContent(id);
    }
  },
  mutations: {}
};
