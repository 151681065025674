import InvoiceService from '../services/invoice.service';

export const invoice = {
  namespaced: true,
  actions: {
    postInvoice({ commit }, body) {
      return InvoiceService.postInvoice(body);
    },

    getProductInvoices({ commit }) {
      return InvoiceService.getProductInvoices();
    }
  },
  mutations: {}
};
