<template>

 <!--[Start] BANNER =========================================== -->
	<section class="banner__header" >						
		<div class="banner__header--content">										
			<div>
				<div class="banner__text">
					<h2>Título da notícia aqui para chamar a atenção do usuário.</h2>
					<p>Breve descrição da notícia para despertar o interesse da leitura de quem passar por aqui.</p>
					
					<router-link class="btn btn-slider"  to="/news-detail" >Iniciar</router-link>
				
				</div>
				<picture aria-label="Título da notícia aqui para chamar a atenção do usuário" >
					<source media="(min-width: 992px)" srcset="@/assets/img/banner-001.jpg">
					<source media="(max-width: 768px)" srcset="@/assets/img/banner-001-m.jpg">
					<img src="@/assets/img/banner-001.jpg" alt="Título da notícia aqui para chamar a atenção do usuário" title="Título da notícia aqui para chamar a atenção do usuário">
				</picture>															
			</div>	

			<div>
				<div class="banner__text">
					<h2>Título da notícia aqui para chamar a atenção do usuário.</h2>
					<p>Breve descrição da notícia para despertar o interesse da leitura de quem passar por aqui.</p>
					<router-link class="btn btn-slider"  to="/news-detail" >Iniciar</router-link>
				
				</div>
				<picture aria-label="Título da notícia aqui para chamar a atenção do usuário" >
					<source media="(min-width: 992px)" srcset="@/assets/img/banner-001.jpg">
					<source media="(max-width: 768px)" srcset="@/assets/img/banner-001-m.jpg">
					<img src="@/assets/img/banner-001.jpg" alt="Título da notícia aqui para chamar a atenção do usuário" title="Título da notícia aqui para chamar a atenção do usuário">
				</picture>															
			</div>	

			<div>
				<div class="banner__text">
					<h2>Título da notícia aqui para chamar a atenção do usuário.</h2>
					<p>Breve descrição da notícia para despertar o interesse da leitura de quem passar por aqui.</p>
					<router-link class="btn btn-slider "  to="/news-detail" >Iniciar</router-link>
				
				</div>
				<picture aria-label="Título da notícia aqui para chamar a atenção do usuário" >
					<source media="(min-width: 992px)" srcset="@/assets/img/banner-001.jpg">
					<source media="(max-width: 768px)" srcset="@/assets/img/banner-001-m.jpg">
					<img src="@/assets/img/banner-001.jpg" alt="Título da notícia aqui para chamar a atenção do usuário" title="Título da notícia aqui para chamar a atenção do usuário">
				</picture>															
			</div>	         				
		</div>								
	</section>
	<!--[END] BANNER =========================================== -->

	<!--[Start] CONTEUDO =========================================== -->
	<main class="masterclass">

        <!-- OCULTAR ESSE MENU QUANDO NAO LOGADO OU SEM TER AULAS INICIADAS-->
		<nav class="sub__menu">
			<router-link href="" class="sub--link active" to=" ">Todas as aulas</router-link> 
			<router-link href="" class="sub--link" to=" ">Em andamento</router-link>  
		</nav>
        <!-- OCULTAR ESSE MENU QUANDO NAO LOGADO OU SEM TER AULAS INICIADAS-->

		<!--[Start] SEARCH =========================================== -->        
		<div class="search__page grid">
			<div class="search__content col-70">
				<input type="search" class="search" placeholder="Pesquisar">
				<button class="btn-search"></button>
			</div>
		   
		</div>
		<!--[END] SEARCH =========================================== -->

        <div class="content grid">

            <div class="class__module col-50">
                <div class="class__module--header">
                    <div class="tags">
                         <router-link href="" to=" ">#Métricas</router-link> 
                    </div>
                    <router-link href="" class="link__module start" to=" ">Iniciar</router-link> 
                </div>

                <div class="class__module--text">
                    <h3>Métricas e proporções</h3>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>            
                 <progress class="class__module--progress" max="100" value="0" ></progress>
            </div>

            <div class="class__module col-50">
                <div class="class__module--header">
                    <div class="tags">
                         <router-link href="" to=" ">#Métricas</router-link> 
                    </div>
                    <router-link href="" class="link__module start" to=" ">Iniciar</router-link> 
                </div>

                <div class="class__module--text">
                    <h3>Métricas e proporções</h3>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
                
                 <progress class="class__module--progress" max="100" value="0" ></progress>
            </div>

            <div class="class__module col-50">
                <div class="class__module--header">
                    <div class="tags">
                         <router-link href="" to=" ">#Métricas</router-link> 
                    </div>
                    <router-link href="" class="link__module start" to=" ">Iniciar</router-link> 
                </div>

                <div class="class__module--text">
                    <h3>Métricas e proporções</h3>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
                
                 <progress class="class__module--progress" max="100" value="0" ></progress>
            </div>

             <div class="class__module col-50">
                <div class="class__module--header">
                    <div class="tags">
                         <router-link href="" to=" ">#Métricas</router-link> 
                    </div>
                    <router-link href="" class="link__module watching" to=" ">Continuar</router-link> 
                </div>

                <div class="class__module--text">
                    <h3>Métricas e proporções</h3>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
                
                 <progress class="class__module--progress" max="100" value="40" ></progress>
            </div>

            <div class="class__module col-50">
                <div class="class__module--header">
                    <div class="tags">
                         <router-link href="" to=" ">#Métricas</router-link> 
                    </div>
                    <router-link href="" class="link__module watching" to=" ">Continuar</router-link> 
                </div>

                <div class="class__module--text">
                    <h3>Métricas e proporções</h3>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
                
                 <progress class="class__module--progress" max="100" value="40" ></progress>
            </div>

            <div class="class__module col-50">
                <div class="class__module--header">
                    <div class="tags">
                         <router-link href="" to=" ">#Métricas</router-link> 
                    </div>
                    <router-link href="" class="link__module watching" to=" ">Continuar</router-link> 
                </div>

                <div class="class__module--text">
                    <h3>Métricas e proporções</h3>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
                
                 <progress class="class__module--progress" max="100" value="40" ></progress>
            </div>

            <div class="class__module col-50">
                <div class="class__module--header">
                    <div class="tags">
                         <router-link href="" to=" ">#Métricas</router-link> 
                    </div>
                    <router-link href="" class="link__module watching" to=" ">Continuar</router-link> 
                </div>

                <div class="class__module--text">
                    <h3>Métricas e proporções</h3>
                    <p>Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
                
                 <progress class="class__module--progress" max="100" value="40" ></progress>
            </div>



        </div>
	
   
  </main>
  

	
</template>

<script>

export default {
 
  created() {
   document.title= 'MasterClass | ' + document.title.substring(document.title.indexOf('|') + 1)
  },

  mounted(){
	  var self=this;
	  if (document.readyState === "complete" || document.readyState === "loaded") {
	 // document is already ready to go
	 self.slideTop();
	}else
	 document.addEventListener("DOMContentLoaded", function() {
	  self.slideTop();
	 });
  },
 
  methods: {
	  slideTop(){
			$('.banner__header').each(function (index, sliderWrap) {
		var $sliderBanner = $(sliderWrap).find('.banner__header--content');
		var $next = $(sliderWrap).find('.next');
		var $prev = $(sliderWrap).find('.prev');
		$sliderBanner.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			// fade: true,
			autoplay: true,
			infinite: true,
			autoplaySpeed: 4000,
			cssEase: 'cubic-bezier(0.6, 0, 0.4, 1)',
			nextArrow: $next,
			prevArrow: $prev,
			arrows: false,
			dots: true,
			lazyLoad: 'progressive',
			pauseOnDotsHover: true,

			responsive: [{
				breakpoint: 992,
				settings: {
					dots: true,
					arrows: false
				}
	
			}, {
	
				breakpoint: 768,
				settings: {
					dots: true,
					arrows: false
				}
		
			}]
		});
	});
	  }
  }
  
}
</script>


