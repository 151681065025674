import { createStore } from "vuex";
import { auth } from "./auth.module";
import { file } from "./file.module";
import { news } from "./news.module";
import { suporte } from "./suporte.module";
import { part } from "./participant.module";
import { videos } from "./videos.module";
import { downloads } from "./downloads.module";
import { product } from "./product.module";
import { invoice } from "./invoice.module";

const store = createStore({
  modules: {
    auth,
    file,
    news,
    suporte,
    part,
    videos,
    downloads,
    product,
    invoice
  },
});

export default store;
