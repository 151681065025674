<template>
    <header>
        <div class="content">
            <div class="logo__header">
                <router-link class="link"  to="/inicio"><img src="@/assets/img/logo-header.png" :alt="$t('MAGENTA_ALTS')" :title="$t('MAGENTA_ALTS')"></router-link>
            </div>  
            <div class="container">
                <nav id="nav">
                    <button aria-label="Abrir Menu" id="btn-mobile" aria-haspopup="true" aria-controls="menu" aria-expanded="false">{{$t('Menu')}}
                        <span id="hamburger"></span>
                    </button>
                    <ul id="menu" role="menu">
                        <li @click="close()"><router-link class="link"  to="/inicio">{{$t('START')}}</router-link></li>
                         <li @click="close()"><router-link class="link"  to="/sobre-nos">{{$t('ABOUT')}}</router-link></li>
                         <!-- <li><router-link class="link"  to="/solucoes">Soluções LG</router-link></li> -->
                         <li @click="close()"><router-link class="link"  to="/news">{{$t('NEWS')}}</router-link></li>
                         <!-- <li><router-link class="link"  to="/master-class">MasterClass</router-link></li> -->
                         <li><router-link class="link" to="/downloads">{{$t('DOWNLOADS')}}</router-link></li>
                         <li><router-link class="link" to="/videos">{{$t('VIDEOS')}}</router-link></li>                    
                         <li><router-link class="link" to="/fale-conosco">{{$t('CONTACT_US')}}</router-link></li>
                        <router-link v-if="loggedIn" class="perfil--mobile" to="/perfil">{{$t('PROFILE')}}</router-link>
                        <router-link @click="close()" class="register--mobile" v-if="!loggedIn" to="/login">{{$t('LOGIN')}}</router-link>
                        <a @click="logout($event)" v-if="loggedIn" class="register--mobile">{{$t('EXIT')}}</a>
                    </ul>
                </nav>

                <div class="login__top">
                    <!-- APLCIAR SOMENTE O PRIMEIRO NOME -->
                    <span>{{$t('HELLO')}}{{loggedIn?', '+$store.state.auth.user?.name:''}}</span>
                    <div class="nav__login">
                        <router-link v-if="!loggedIn" to="/login">{{$t('IN')}}</router-link>
                        <router-link v-if="!loggedIn" to="/cadastre-se">{{$t('NOT_REGISTER')}}</router-link>
                        <router-link v-if="loggedIn" class="" to="/perfil">{{$t('PROFILE')}}</router-link>   
                        <a @click="logout($event)" v-if="loggedIn" class="exit">{{$t('EXIT')}}</a>
                    </div>
                </div>         
            </div>
            
        </div>
    </header>
</template>


<script>
//user?.name
export default {
    data(){
        return{
            url: window.location.pathname,
            user: {name:''}
        }
    },

    created(){
        this.user = this.$store.state.auth.user;
    },
    computed :{
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    mounted(){
	  var self=this;
	  if (document.readyState === "complete" || document.readyState === "loaded") {
	 // document is already ready to go
	 self.MenuMobile();
	}else
	 document.addEventListener("DOMContentLoaded", function() {
	  self.MenuMobile();
	 });
    },
 
     methods: {
	  MenuMobile(){
        const btnMobile = document.getElementById('btn-mobile');

        function toggleMenu(event) {
        if (event.type === 'touchstart') event.preventDefault();
        const nav = document.getElementById('nav');
        nav.classList.toggle('active');
        const active = nav.classList.contains('active');
        event.currentTarget.setAttribute('aria-expanded', active);
        if (active) {
            event.currentTarget.setAttribute('aria-label', 'Fechar Menu');
        } else {
            event.currentTarget.setAttribute('aria-label', 'Abrir Menu');
        }
        }

        btnMobile.addEventListener('click', toggleMenu);
        btnMobile.addEventListener('touchstart', toggleMenu);

	},
    logout(e) {
            e.preventDefault();
            this.$store.dispatch('auth/logout');
        }	
  }
}
</script>
