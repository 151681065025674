import api from "./api";
import TokenService from "./token.service";
import router from '@/router'

class AuthService {
  login(user) {
    return api
      .post('auth/login-participant', {
        email: user.username,
        password: user.password,
        token: user.token
      })
      .then(response => {
        if (response.data.access_token) {
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  token(username) {
    return api
      .post('auth/token', {
        email: username
      })
      .then(response => {
        return response.data;
      });
  }

  reset(username, token) {
    return api
      .post('auth/reset-password-participant', {
        email: username,
        token: token
      })
      .then(response => {
        return response.data;
      });
  }

  logout() {
    api.get('auth/logout').then((response, status) => {
      if (status == 204)
        console.log("Logout success");
    });
    TokenService.removeUser();
    router.push('/login'); 
  }

  registerParticipant(participant) {
    return api.post('participants', participant);
  }
  async changeLang(id, lang){
    await api.put('users/'+id, {language: lang}).then(
      response =>{
        TokenService.setLang(response.data?.language);
        return response.data;
      }
    );
  }
  refreshToken() {
    return api.get("auth/refresh").then(response => {
      if(response.status == 401){
        TokenService.removeUser();
        router.push('/login');
        return response.data;
      }

      if (response.data.access_token) {
        TokenService.setUser(response.data);
      }

      return response.data;
    },
    error =>{
      if(response.status == 401){
        TokenService.removeUser();
        router.push('/login');
        return response.data;
      }
    }).catch(err =>{
      TokenService.removeUser();
      router.push('/login');
      return Promise.reject(error);
    });
  }

  refreshTokenAsync() {
    return api.get("auth/refresh");
  }

  async changeLang(id, lang){
    await api.put('users/'+id, {language: lang}).then(
      response =>{
        TokenService.setLang(response.data?.language);
        return response.data;
      }
    );
  }
}

export default new AuthService();
