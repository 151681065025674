import api from "./api";

class NewsService {
  allNews(order, limit, skip, banner) {
    var req = 'newsletter?publish=1&';
    if(order && order.length > 0)
      req+='order='+order+'&';
    if(limit && limit !== null && limit !== '')
      req+='limit='+limit+'&';
    if(skip && skip !== null && skip !== '')
      req+='skip='+skip+'&';
    if(banner && banner !== null && banner !== '')
      req+='banner='+banner+'&';
    return api.get(req);
  }
  allNewsFilter(exclude) {
    return api.get('newsletter?publish=1&limit=2&exclude='+exclude);
  }
  news(id) {
    return api.get('newsletter/'+id);
  }
  image(ext) {
    return api.get('newsletter/renderimagem/'+ext);
  }
}

export default new NewsService();