import api from "./api";

class DownloadsService {
  getContents(order, limit, skip, category, search, contentType, sort, showMain) {
    var req = 'contents?publish=1&';
    if(order && order.length > 0)
      req+='order='+order+'&';
    if(limit && limit !== null && limit !== '')
      req+='limit='+limit+'&';
    if(skip && skip !== null && skip !== '')
      req+='skip='+skip+'&';
    if(category && category !== null && category !== '')
      req+='category='+category+'&';
    if(search && search !== null && search !== '')
      req+='search='+search+'&';
    if(contentType && contentType !== null && contentType !== '')
      req+='contentType='+contentType+'&';
    if(sort && sort !== null && sort !== '')
      req+='sort='+sort+'&';
    if(showMain && showMain !== null && showMain !== '')
      req+='showMain='+showMain+'&';
    return api.get(req);
  }

  getTypes(){
    return api.get('contents-types');
  }

  getCategories(){
    return api.get('contents/category');
  }

  getContent(id){
    return api.get('contents/'+id);
  }
}

export default new DownloadsService();
