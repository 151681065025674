<template>
    <footer>
        <div class="footer__content">
            <div class="footer__content--logo">
                <img src="@/assets/img/logo-footer-lg.png" :alt="$t('LOGO_LG')" :title="$t('LOGO_LG')">
            </div>

            <div class="footer__content--map">
                <div class="map--column">
                    <h6>{{$t('LG_MAGENTA')}}</h6>
                    <router-link href="" class="link--footer" to="/inicio">{{$t('START')}}</router-link>
                    <router-link href="" class="link--footer" to="/sobre-nos">{{$t('ABOUT')}}</router-link>
                </div>
                <div class="map--column">
                    <h6>{{$t('CONTENTS')}}</h6>
                    <!-- <router-link href="" class="link--footer" to="/solucoes">Soluções LG</router-link> -->
                    <router-link href="" class="link--footer" to="/news">{{$t('NEWS')}}</router-link>
                    <!-- <router-link href="" class="link--footer" to="/master-class">MasterClass</router-link>-->
                    <router-link href="" class="link--footer" to="/downloads">{{$t('DOWNLOADS')}}</router-link>
                    <router-link href="" class="link--footer" to="/videos">{{$t('VIDEOS')}}</router-link> 
                </div>
                <div class="map--column">
                    <h6>{{$t('PROF')}}</h6>
                    <router-link href="" class="link--footer" to="/perfil">{{$t('PROFILE')}}</router-link>
                </div>
                <div class="map--column">
                    <h6>{{$t('CONTACT_US')}}</h6>
                    <router-link class="link--footer" to="/fale-conosco">{{$t('CONTACT_US')}}</router-link>
                    <!-- <router-link class="link--footer" to="/termos-politicas">Termos e Políticas</router-link> -->
                </div>
                <div class="map--column">
                    <h6>{{$t('LANG')}}</h6>
                    <select @change="changeLang" v-model="$i18n.locale">
                        <option>en</option>
                        <option>pt</option>
                    </select>
                </div>
            </div> 
            
            <div class="footer__content--social">
                <a href="https://instagram.com/lgdobrasil" target="_blank" class="link-social"><img src="@/assets/img/instagram-icon.svg" alt="Instagram" title="Instagram"></a>
                <!-- <a href="" target="_blank" class="link-social"><img src="@/assets/img/linkedin-icon.svg" alt="LinkedIn" title="LinkedIn"></a> -->
                <a href="http://facebook.com/lgdobrasil" target="_blank" class="link-social"><img src="@/assets/img/facebook-icon.svg" alt="FaceBook" title="FaceBook"></a>
                <a href="https://www.youtube.com/user/LGdoBrasil" target="_blank" class="link-social"><img src="@/assets/img/youtube-icon.svg" alt="YouTube" title="YouTube"></a>
                <a href="https://wa.me/5511959669600" target="_blank" class="link-social"><img src="@/assets/img/whatsapp-icon.svg" alt="WhatsApp" title="WhatsApp"></a>
            </div>
        </div>
        <p>{{$t('LEGAL_LG')}}</p>
        <p>{{$t('VERSION')}}<b>{{version}}</b></p>
        <p>{{$t('ENV')}}<b>{{env}}</b></p>
    </footer>
</template>


<script>


export default {
    data(){
        return{
            version: process.env.VUE_APP_VERSION,
            env: process.env.NODE_ENV.toUpperCase(),
            url: window.location.pathname,
            user: {}
        }
    },

    created(){
        this.user = this.$store.state.auth.user;
    },
    methods:{
        logOut() {
            this.$store.dispatch('auth/logout');
        },
        changeLang(){
            if(this.$store.state.auth.user)
                this.$store.dispatch('auth/changeLang', [this.$store.state.auth.user.id, this.$i18n.locale]);
        }
    }
}
</script>
