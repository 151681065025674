<template>
	<main class="login__token">  

        <div class="tittle__wrap">
            <h1>{{$t('PASS_RECOVERY')}}</h1>
            <p>{{$t('PASS_RECOVERY_1')}} <br>
                {{$t('FILL_CODE')}}</p>
        </div>

        <div class="login__token--content">

            <form>
                         
                <div class="form__content">       
                    <input v-model="tokenInput['1']" class="tokenInput" ref="one" name="" type="text" maxlength="1" minlength="1" @input="this.$refs.two.focus()" @focus="this.$refs.one.select()">        
                    <input v-model="tokenInput['2']" class="tokenInput" ref="two" name="" type="text" maxlength="1" minlength="1" @input="this.$refs.tree.focus()" @focus="this.$refs.two.select()">        
                    <input v-model="tokenInput['3']" class="tokenInput" ref="tree" type="text" maxlength="1" minlength="1" @input="this.$refs.four.focus()" @focus="this.$refs.tree.select()">              
                    <input v-model="tokenInput['4']" class="tokenInput" ref="four" type="text" maxlength="1" minlength="1" @input="this.$refs.five.focus()" @focus="this.$refs.four.select()">  
                    <input v-model="tokenInput['5']" class="tokenInput" ref="five" type="text" maxlength="1" minlength="1" @input="this.$refs.six.focus()" @focus="this.$refs.five.select()">               
                    <input v-model="tokenInput['6']" class="tokenInput" ref="six" type="text" maxlength="1" minlength="1" @input="this.$refs.seven.focus()" @focus="this.$refs.six.select()">
                    <input v-model="tokenInput['7']" class="tokenInput" ref="seven" type="text" maxlength="1" minlength="1" @input="this.$refs.h.focus()" @focus="this.$refs.seven.select()">
                    <input v-model="tokenInput['8']" class="tokenInput" ref="h" type="text" maxlength="1" minlength="1" @focus="this.$refs.h.select()">
                </div>                
                <span class="error__form" v-show="errorLabel.fail">{{errorLabel.message}}</span>
                <button class="btn btn--form" type="button" @click="resetPass()" :disabled="!resetPassDis" >{{$t('CODE_VALID')}}</button>
                <a href="#" @click="resendToken($event)" class="send__code">{{$t('CODE_RESEND')}}</a>
                <span class="error__form" v-show="resend.show">{{resend.message}}</span>          
            </form>
        </div>
    </main>
</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      message: "",	  
	  user:{
	    username:this.$store.state.auth.status.reset.email,
	    password:"",
	    token:"",
	  },
    tokenInput:{
      "1": "",
      "2": "",
      "3": "",
      "4": "",
      "5": "",
      "6": "",
      "7": "",
      "8": "",
      token: ""
    },
	  resend:{
      show: false,
      message:""
    },
    errorLabel: {
      fail: false,
      message: ""
    }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    resetPassDis : function (){
      return this.tokenInput['1'].length > 0 && 
        this.tokenInput['2'].length > 0 && 
        this.tokenInput['3'].length > 0 && 
        this.tokenInput['4'].length > 0 && 
        this.tokenInput['5'].length > 0 && 
        this.tokenInput['6'].length > 0 && 
        this.tokenInput['7'].length > 0 && 
        this.tokenInput['8'].length > 0;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/news");
    }

    if(!this.user.username || this.user.username.trim().length <= 0)
      this.$router.push("/login");

  },
  methods: {
	resetPass(){
		this.tokenInput.token = this.tokenInput['1'] + this.tokenInput['2']+this.tokenInput['3'] +this.tokenInput['4']+
        this.tokenInput['5'] +this.tokenInput['6'] +this.tokenInput['7']+this.tokenInput['8'];
    console.log(this.tokenInput.token);
    if(!this.tokenInput.token || this.tokenInput.token.length != 8){
      this.errorLabel.fail = true;
			this.errorLabel.message = "Por favor preencha o Token!";
			return;
    }
		this.$store.dispatch("auth/reset", [this.user.username, this.tokenInput.token]).then(
        (response) => {
          if(response === "DONE")
            this.$router.push("/login");
          else
            this.errorLabel.fail = true;
            this.errorLabel.message = error.response.data.message
        },
        (error) => {
            this.errorLabel.fail = true;
            this.errorLabel.message = error.response.data.message
        }).catch(error => {
          this.errorLabel.fail = true;
          this.errorLabel.reset = "Erro ao enviar Token"
        });
	},
  resendToken(event){
    console.log(this.user.username);
    event.preventDefault();
    if (this.user.username.trim().length > 0) {
      this.$store.dispatch("auth/token", this.user.username).then(
        (response) => {
          if(response === "DONE"){
            this.resend.show = true;
            this.resend.message = "Token Reenviado."
          }else{
            this.resend.show = true;
            this.resend.message =  error.response.data.message
          } 
        },
        (error) => {
          this.resend.show = true;
            this.resend.message = error.response.data.message
			  }).catch(error => {
          this.resend.show = true;
            this.resend.message = "Erro ao enviar Token"
        });
    }else {
      this.resend.show = true;
      this.resend.message = "Preencha o email, por favor.";
    }
  }
  },
  mounted (){
    const targets = document.getElementsByClassName('tokenInput');
      for (let index = 0; index < targets.length; index++) {
        targets[index].addEventListener('paste', (event) => {
          let paste = (event.clipboardData || window.clipboardData).getData('text');
          for (let idx = 0; idx < 8; idx++) {
            if(idx >= paste.length)
              break;
            this.tokenInput[idx+1] = paste.substring(idx, idx+1);
            targets[idx].value = paste.substring(idx, idx+1);
          }
          event.preventDefault();
        });  
      }
  }
};
</script>

